import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UserApi } from "../../../apis/userApi";

export const loginUser = createAsyncThunk("user/loginUser", async () => {
  const user = await UserApi.getMe();
  return user;
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: {},
    status: "idle",
    error: false,
    activeSubscriberId: null,
  },
  reducers: {
    logout(state) {
      return {
        id: null,
        subscriberId: null,
        email: null,
        firstName: null,
        lastName: null,
        avatar: null,
        subscribers: [],
      };
    },
    setActiveSubscriber: (state, action) => {
      state.activeSubscriberId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        let user = action.payload;

        //Restore last active subscribers session
        let localSuscriberId = localStorage.getItem("localSubscriberId");
        if (
          localSuscriberId &&
          user.subscribers.length > 0 &&
          user.subscribers.some(
            (subscriber) => subscriber.id === localSuscriberId
          )
        ) {
          state.activeSubscriberId = localSuscriberId;
        } else {
          state.activeSubscriberId =
            user.subscribers.length > 0 ? user.subscribers[0].id : null;
        }

        state.data.id = user.id;
        state.data.subscriberId = user.subscriberId;
        state.data.email = user.email;
        state.data.firstName = user.firstName ? user.firstName : "firstName";
        state.data.lastName = user.lastName ? user.lastName : "lastName";
        state.data.avatar = user.avatar ? user.avatar : "logo.png";
        state.data.subscribers = user.subscribers ? user.subscribers : [];
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;

        state.id = null;
        state.subscriberId = null;
        state.email = null;
        state.firstName = null;
        state.lastName = null;
        state.avatar = null;
      });
  },
});

export const selectUserData = (state) => state.user.data;
export const selectUserSubscribers = (state) => state.user.data.subscribers;
export const selectActiveUserId = (state) => state.user.activeSubscriberId;
export const selectUserStatus = (state) => state.user.status;
export const selectUserError = (state) => state.user.error;

export const { logout, setActiveSubscriber } = userSlice.actions;

export default userSlice.reducer;

import {
  Box,
  Container,
  Stack,
  Typography,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material";
import React from "react";
import ClockAnimation from "./components/ClockAnimation";
import {
  primaryBlue,
  primaryBgc,
  secondaryBlue,
  secondary,
} from "../utils/jsutils";
import ProductAnimationLarge from "./components/ProductAnimationLarge";
import ProductAnimationSmall from "./components/ProductAnimationSmall";

function ProductPage() {
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div id="product-page" style={{ backgroundColor: primaryBgc }}>
      <Box
        sx={{
          backgroundColor: primaryBlue,
          display: "flex",
          flexGrow: 1,
          height: 100,
        }}
      >
        <Box
          sx={{
            backgroundColor: primaryBgc,
            display: "flex",
            flexGrow: 1,
            height: 100,
            borderRadius: smallscreen ? "0 0 4em 0" : "0 0 7em 0",
          }}
        />
      </Box>
      <Box
        sx={{
          pb: { xl: 0, lg: 5, md: 5, sm: 20, xs: 10 },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Paper
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // width: { xl: "45%", lg: "40%", md: "48%", sm: "60%", xs: "70%" },
              width: { xl: 760, lg: 600, md: "48%", sm: "60%", xs: "90%" },

              overflow: "clip",
              position: "absolute",
              top: { xl: 40, lg: 50, md: 60, sm: 300, xs: 360 },
              left: { xl: "6%", lg: "4%", md: "2%", sm: "30%", xs: "5%" },
            }}
            elevation={0}
          >
            <img
              src="images/homescreen.svg"
              width={"100%"}
              alt="1bonding homescreen"
            />
          </Paper>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Box
            sx={{
              backgroundColor: primaryBlue,
              borderRadius: smallscreen ? "4em 0 0 0" : "6em 0 0 0",
              display: "flex",
              justifyContent: "end",
              pr: 2,
              width: smallscreen ? "95%" : "80%",
            }}
          >
            <Stack
              spacing={2}
              sx={{
                width: {
                  xl: "50%",
                  lg: "55%",
                  md: "50%",
                  sm: "90%",
                  xs: 320,
                },
                pb: {
                  xl: 15,
                  lg: "5em",
                  md: "6em",
                  sm: "10em",
                  xs: "18em",
                },
                pt: { xl: 15, lg: 9, md: 10, sm: 8, xs: 5 },
                pl: smallscreen ? 2 : 0,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontWeight: 600,
                  fontSize: {
                    xl: "1.5rem",
                    lg: "1.2rem",
                    md: "1rem",
                    sm: "1.1rem",
                    xs: "1.1rem",
                  },
                }}
              >
                Service management integrations are more critical than ever
                before. Ensuring seamless operations, data accuracy, efficient
                workflows, security, and scalability are the foundations for
                mission critical Service Management integrations.
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  fontWeight: 600,
                  fontSize: {
                    xl: "1.5rem",
                    lg: "1.2rem",
                    md: "1rem",
                    sm: "1.1rem",
                    xs: "1.1rem",
                  },
                }}
              >
                1Bonding was designed to help you master these challenges – all
                in record time.
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          pt: { xl: "2rem", lg: "2rem", md: "1rem", sm: "1rem", xs: "0.5rem" },
          pb: { xl: "4rem", lg: "4rem", md: "4rem", sm: "1rem", xs: "0.5rem" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container maxWidth="xl">
          <ClockAnimation />
        </Container>
        <Box
          sx={{
            backgroundColor: secondaryBlue,
            display: "flex",
            flexGrow: 1,
            height: 100,
          }}
        >
          <Box
            sx={{
              backgroundColor: primaryBgc,
              display: "flex",
              flexGrow: 1,
              height: 100,
              borderRadius: "0 0 7em 0",
            }}
          />
        </Box>

        <Box
          sx={{
            pt: "4rem",
            pb: "4rem",
            display: "flex",
            flexDirection: "column",
            backgroundColor: secondaryBlue,
          }}
        >
          <Container maxWidth="lg">
            <Stack spacing={2}>
              <Typography
                sx={{
                  fontWeight: 600,
                  color: "white",
                  textDecoration: "underline",
                  textDecorationColor: secondary,
                }}
                variant="h5"
              >
                Simplify Service Management bonds to just four steps with
                1Bonding
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                }}
                color="white"
                variant="h6"
              >
                You’ll always have full control of what tickets, incidents, and
                requests are shared. And after you’ve made your first
                integration, creating new bonds with additional partners are
                even easier. You’ll be more efficient, reduce on-boarding time,
                and ensure better service, all while reducing cost.
              </Typography>
            </Stack>
          </Container>
        </Box>

        <ProductAnimationLarge />
        <ProductAnimationSmall />
      </Box>
    </div>
  );
}

export default ProductPage;

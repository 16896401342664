export const isObjectEmpty = (object) => {
  return Object.keys(object).length === 0;
};

// HH:MM:SS
export const timeFromEpoc = (epocTimestamp) => {
  if (!epocTimestamp) return null;
  const multiplier = epocTimestamp.toString().length === 10 ? 1000 : 1;
  var date = new Date(epocTimestamp * multiplier);
  var hours = date.getHours();
  if (hours < 10) hours = "0" + hours;
  var minutes = "0" + date.getMinutes();
  var seconds = "0" + date.getSeconds();
  return hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
};
// DD/MM/YYY
export const calendarDateFromEpoc = (epocTimestamp) => {
  if (!epocTimestamp) return null;
  const multiplier = epocTimestamp.toString().length === 10 ? 1000 : 1;
  var date = new Date(epocTimestamp * multiplier);
  var day = date.getDate().toString().padStart(2, "0");
  var month = (date.getMonth() + 1).toString().padStart(2, "0");
  var year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
// DD MMM YYYY
export const descDateFromEpoc = (epocTimestamp) => {
  if (!epocTimestamp) return null;
  const multiplier = epocTimestamp.toString().length === 10 ? 1000 : 1;
  const date = new Date(epocTimestamp * multiplier);
  const options = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};
// X TIME AGO
export const timeElapsedFromEpoc = (epocTimestamp) => {
  if (!epocTimestamp) return null;
  const isShortFormat = epocTimestamp.toString().length === 10;
  const multiplier = isShortFormat ? 1000 : 1;
  var currentTime = new Date().getTime();
  var timeElapsed = currentTime - epocTimestamp * multiplier; // convert to milliseconds
  var seconds = Math.floor(timeElapsed / 1000);
  var interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    return interval + (interval > 1 ? " years ago" : " year ago");
  }
  interval = Math.floor(seconds / 2592000) % 12;
  if (interval >= 1) {
    return interval + (interval > 1 ? " months ago" : " month ago");
  }
  interval = Math.floor(seconds / 86400) % 30;
  if (interval >= 1) {
    return interval + (interval > 1 ? " days ago" : " day ago");
  }
  interval = Math.floor(seconds / 3600) % 24;
  if (interval >= 1) {
    return interval + (interval > 1 ? " hours ago" : " hour ago");
  }
  interval = Math.floor(seconds / 60) % 60;
  if (interval >= 1) {
    return interval + (interval > 1 ? " minutes ago" : " minute ago");
  }
  return Math.floor(seconds) + (seconds > 1 ? " seconds ago" : " second ago");
};

export const primaryBlue = "#1f3f66";
export const secondaryBlue = "#587CB8";
export const secondary = "#f18944";
export const primaryBgc = "#f0f4fc";

//old plugindefs have required stored as string, and some dont contain required fields
export function parseRequiredField(obj) {
  if (obj && obj.required) {
    return obj.required === "true";
  }
  return false;
}

export function parseBoolean(obj) {
  if (obj && obj.value) {
    return obj.value === "true";
  }
  return false;
}

export function findProfileByProfileType(profiles) {
  let profile;
  profile = profiles.find((profile) => {
    return profile.fields.find(
      (field) => field.name === "profileType" && field.value === "endpoint"
    );
  });

  if (profile) return profile;
  return null;
}

import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useInView } from "react-intersection-observer";

function ProductAnimationSmall() {
  let colors = ["#67c7cf", "#28afa3", "#68959D", "#afc96d"];

  const [ref1, inView1] = useInView({
    threshold: 0,
  });

  const [ref2, inView2] = useInView({
    threshold: 0,
  });

  const [ref3, inView3] = useInView({
    threshold: 0,
  });

  const [ref4, inView4] = useInView({
    threshold: 0,
  });

  const [ref5, inView5] = useInView({
    threshold: 0,
  });

  return (
    <Box
      id="product-animation-small"
      sx={{
        display: {
          xl: "none",
          lg: "none",
          md: "none",
          sm: "flex",
          xs: "flex",
        },
        flexDirection: "column",
      }}
    >
      <Container maxWidth="sm">
        {/*  ######################  GRID 1 ######################  */}

        <Box id="product-animation-1">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                m: "auto",
                height: "55vh",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: 50,
                    padding: 5,
                    // transform: "scale(1.4)",
                    backgroundColor: "#abcb5d26",
                    borderRadius: "100%",
                    position: "absolute",
                    top: 60,
                    left: 150,
                  }}
                >
                  <img src="images/test-sd1.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    width: 50,
                    padding: 5,
                    transform: `scale(${inView1 ? 1 : 0})`,
                    backgroundColor: "#4d7cbe26",
                    borderRadius: "100%",
                    position: "absolute",
                    top: 180,
                    left: 30,
                    transition: "all 0.2s ease-in 0.3s",
                  }}
                >
                  <img src="images/test-logo.svg" width="100%" />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                  pl: "2rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", ml: -1 }}>
                  <Typography
                    sx={{ fontWeight: 600, color: colors[0] }}
                    variant="h3"
                  >
                    1
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 600, pb: 0.5, pl: 2 }}
                    color="primary"
                    variant="h6"
                  >
                    Sign in to 1Bonding
                  </Typography>
                  <Box ref={ref1} sx={{ height: "1px" }}></Box>
                </Box>
                {[
                  "Setting up 1Bonding is easy.  Let’s say you are a Service Provider who wants to integrate your Service Desk with a customer’s.",
                  "First, you’ll select your Service Desk from a long list of supported options.",
                ].map((text, index, arr) => (
                  <React.Fragment key={text}>
                    <Typography
                      color="primary"
                      sx={{ mt: 1 }}
                      variant="body1"
                      paragraph
                    >
                      {text}
                    </Typography>
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/*  ######################  GRID 2 ######################  */}

        <Box id="product-animation-2">
          <Grid container spacing={2} sx={{}}>
            <Grid
              item
              xs={12}
              sx={{
                m: "auto",
                height: "55vh",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: 50,
                    padding: 5,
                    transform: `scale(${inView2 ? 1.4 : 1})`,
                    transition: "all 0.2s ease-in 0.3s",
                    backgroundColor: "#abcb5d26",
                    borderRadius: "100%",
                    position: "absolute",
                    top: 60,
                    left: 150,
                  }}
                >
                  <img src="images/test-sd1.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    width: 35,
                    top: 170,
                    left: 140,
                    transition: "all 0.2s ease-in 0.5s",
                    zIndex: 100,
                    transform: `scale(${inView2 ? 1 : 0})`,
                  }}
                >
                  <img src="images/test-shield1.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    width: 50,
                    padding: 5,
                    transform: `scale(${inView2 ? 1.4 : 1})`,
                    backgroundColor: "#4d7cbe26",
                    borderRadius: "100%",
                    position: "absolute",
                    top: 180,
                    left: 30,
                    transition: "all 0.2s ease-in 0.3s",
                  }}
                >
                  <img src="images/test-logo.svg" width="100%" />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                  pl: "2rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", ml: -1 }}>
                  <Typography
                    sx={{ fontWeight: 600, color: colors[1] }}
                    variant="h3"
                  >
                    2
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 600, pb: 0.5, pl: 2 }}
                    color="primary"
                    variant="h6"
                  >
                    Connect your Service Desk
                  </Typography>
                  <Box ref={ref2} sx={{ height: "1px" }}></Box>
                </Box>
                {[
                  "A 1Bonding Secure Broker will be automatically provisioned, serving as your private broker for secure data exchange. You’ll configure your Secure Broker with your URL and service desk credentials.​",
                  "1Bonding will validate that your configuration is correct.  You’ll always have full control of access to your system and your data.",
                ].map((text, index, arr) => (
                  <React.Fragment key={text}>
                    <Typography
                      color="primary"
                      sx={{ mt: 1 }}
                      variant="body1"
                      paragraph
                    >
                      {text}
                    </Typography>
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/*  ######################  GRID 3 ######################  */}

        <Box id="product-animation-3">
          <Grid container spacing={2} sx={{}}>
            <Grid
              item
              xs={12}
              sx={{
                m: "auto",
                height: "55vh",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: 40,
                    padding: 5,
                    transition: "all 0.2s ease-in 0.3s",
                    backgroundColor: "#abcb5d26",
                    borderRadius: "100%",
                    position: "absolute",
                    top: 30,
                    left: 80,
                  }}
                >
                  <img src="images/test-sd1.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    width: 40,
                    position: "absolute",
                    top: 80,
                    left: 180,
                    transform: `translate3d(${
                      inView3 ? "0px, 170px, 0px" : "0"
                    })`,
                    transition: "all 0.2s ease-in 0.3s",
                    zIndex: 100,
                  }}
                >
                  <img src="images/test-invite.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    width: 40,
                    padding: 3,
                    transition: "all 0.2s ease-in 0.3s",
                    transform: `scale(${inView3 ? 1 : 0})`,
                    backgroundColor: "#2aafa326",
                    borderRadius: "100%",
                    position: "absolute",
                    top: 220,
                    left: 97,
                  }}
                >
                  <img src="images/test-sd2.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    width: 20,
                    top: 123,
                    left: 95,
                    transition: "all 0.2s ease-in 0.5s",
                    zIndex: 100,
                  }}
                >
                  <img src="images/test-shield1.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    width: 40,
                    padding: 5,
                    backgroundColor: "#4d7cbe26",
                    borderRadius: "100%",
                    position: "absolute",
                    top: 110,
                    left: 10,
                    transition: "all 0.2s ease-in 0.3s",
                  }}
                >
                  <img src="images/test-logo.svg" width="100%" />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                  pl: "2rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", ml: -1 }}>
                  <Typography
                    sx={{ fontWeight: 600, color: colors[2] }}
                    variant="h3"
                  >
                    3
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 600, pb: 0.5, pl: 2 }}
                    color="primary"
                    variant="h6"
                  >
                    Invite Your Customers to Join You on 1Bonding
                  </Typography>
                  <Box ref={ref3} sx={{ height: "1px" }}></Box>
                </Box>
                {[
                  "Now that you’ve set up 1Bonding, you’re ready to integrate your Service Desk (or as we call it, “bond”) with your first customer.",
                  "Simply send them an invitation directly from the 1Bonding portal.​",
                ].map((text, index, arr) => (
                  <React.Fragment key={text}>
                    <Typography
                      color="primary"
                      sx={{ mt: 1 }}
                      variant="body1"
                      paragraph
                    >
                      {text}
                    </Typography>
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/*  ######################  GRID 4 ######################  */}

        <Box id="product-animation-4">
          <Grid container spacing={2} sx={{}}>
            <Grid
              item
              xs={12}
              sx={{
                m: "auto",
                height: "55vh",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: 40,
                    padding: 5,
                    transition: "all 0.2s ease-in 0.3s",
                    backgroundColor: "#abcb5d26",
                    borderRadius: "100%",
                    position: "absolute",
                    top: 30,
                    left: 80,
                  }}
                >
                  <img src="images/test-sd1.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    width: 40,
                    padding: inView4 ? 5 : 3,
                    transition: "all 0.2s ease-in 0.3s",
                    transform: inView4 ? `translate3d(-19px, -26px, 0px)` : "",
                    backgroundColor: "#2aafa326",
                    borderRadius: "100%",
                    position: "absolute",
                    top: 220,
                    left: 97,
                  }}
                >
                  <img src="images/test-sd2.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    width: 20,
                    top: 123,
                    left: 95,
                    transition: "all 0.2s ease-in 0.5s",
                    zIndex: 100,
                  }}
                >
                  <img src="images/test-shield1.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    width: 20,
                    top: 203,
                    left: 95,
                    transition: "all 0.2s ease-in 0.5s",
                    transform: `scale(${inView4 ? 1 : 0})`,
                    zIndex: 100,
                  }}
                >
                  <img src="images/test-shield2.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    width: 40,
                    padding: 5,
                    backgroundColor: "#4d7cbe26",
                    borderRadius: "100%",
                    position: "absolute",
                    top: 110,
                    left: 10,
                    transition: "all 0.2s ease-in 0.3s",
                  }}
                >
                  <img src="images/test-logo.svg" width="100%" />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                  pl: "2rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", ml: -1 }}>
                  <Typography
                    sx={{ fontWeight: 600, color: colors[3] }}
                    variant="h3"
                  >
                    4
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 600, pb: 0.5, pl: 2 }}
                    color="primary"
                    variant="h6"
                  >
                    Your Customer Follows the Same Easy Steps to Join 1Bonding
                  </Typography>
                  <Box ref={ref4} sx={{ height: "1px" }}></Box>
                </Box>
                {[
                  "When your customer accepts your invitation to bond they will go through the same process to connect their service desk to 1Bonding that you did.",
                  "Since your customer will have their own Secure Broker, you don’t need to ask them for Service Desk credentials or API access – 1Bonding eliminates the need to share that information.  Just like you, your customer always stays in control of their data and with whom it is shared.​",
                ].map((text, index, arr) => (
                  <React.Fragment key={text}>
                    <Typography
                      color="primary"
                      sx={{ mt: 1 }}
                      variant="body1"
                      paragraph
                    >
                      {text}
                    </Typography>
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/*  ######################  GRID 5 ######################  */}

        <Box id="product-animation-5">
          <Grid container spacing={2} sx={{}}>
            <Grid
              item
              xs={12}
              sx={{
                m: "auto",
                height: "55vh",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    transform: inView5 ? "translateX(0)" : "translateX(-10%)",
                    transition: "all 0.4s ease-in 0s",
                    opacity: inView5 ? 1 : 0,
                    pr: 5,
                  }}
                >
                  <img src="images/product_5.svg" width="100%" />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                  pl: "2rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", ml: -1 }}>
                  <Typography
                    sx={{ fontWeight: 600, pb: 0.5, pl: 2 }}
                    color="primary"
                    variant="h6"
                  >
                    That’s it. Now you and your customers can share tickets.
                  </Typography>
                  <Box ref={ref5} sx={{ height: "1px" }}></Box>
                </Box>
                {[
                  "1Bonding is designed to enable as many customer connections as your business requires.  Have a new customer?  Since you are already connected to 1Bonding, your work is already done.  Simply send them an invitation to connect.​",
                  "With 1Bonding, you’ll standardize all your service management integrations and manage them centrally.",
                ].map((text, index, arr) => (
                  <React.Fragment key={text}>
                    <Typography
                      color="primary"
                      sx={{ mt: 1 }}
                      variant="body1"
                      paragraph
                    >
                      {text}
                    </Typography>
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default ProductAnimationSmall;

import api from "./config";

export const ContainersApi = {
  getContainers: async function (subscriberId) {
    let url = "personal/containers";
    if (subscriberId !== undefined && subscriberId !== null)
      url += `?subscriberId=${subscriberId}`;
    const response = await api.request({
      url: url,
      method: "GET",
    });
    return response.data;
  },
};

import api from "./config";

export const AuditApi = {
  getAudit: async function (subscriberId) {
    try {
      let url = "personal/audit/record";
      if (subscriberId !== undefined && subscriberId !== null)
        url += `?subscriberId=${subscriberId}`;
      const response = await api.request({
        url: url,
        method: "GET",
      });
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  getTransactionsById: async function (subscriberId, recordId) {
    try {
      let url = "personal/audit/transaction";
      url += `?subscriberId=${subscriberId}${
        recordId ? "&recordId=" + recordId : null
      }`;
      const response = await api.request({
        url: url,
        method: "GET",
      });
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  getTransactionLog: async function (subscriberId, transactionId) {
    try {
      const response = await api.request({
        url: "personal/proxy",
        method: "POST",
        data: {
          method: "GET",
          endpoint: `/ws/rest/audit/log?id=${transactionId}`,
          subscriberId: subscriberId,
        },
      });

      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  getTransactionMessage: async function (subscriberId, transactionId) {
    try {
      const response = await api.request({
        url: "personal/proxy",
        method: "POST",
        data: {
          method: "GET",
          endpoint: `/ws/rest/audit/message?id=${transactionId}`,
          subscriberId: subscriberId,
        },
      });

      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  retryTransactionMessage: async function (subscriberId, message) {
    try {
      const response = await api.request({
        url: "personal/proxy",
        method: "POST",
        data: {
          method: "POST",
          endpoint: `/api/v3/message/retry`,
          subscriberId: subscriberId,
          data: JSON.stringify(message),
        },
      });

      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

import React from "react";
import { Construction } from "@mui/icons-material";
import { Container, Typography } from "@mui/material";

function AccountPage() {
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Construction
        color="primary"
        sx={{
          marginTop: 10,
          fontSize: "10rem  ",
          p: 2,
        }}
      />
      <Typography variant="h6">Coming soon</Typography>
    </Container>
  );
}

export default AccountPage;

import React, { useEffect } from "react";
import { CircularProgress, Link, Typography } from "@mui/material";
import { Launch } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { loadContainers } from "../state/features/container/container";
import { selectActiveUserId } from "../state/features/user/user";

function LinkTo1Gateway() {
  const container = useSelector((state) => state.container);
  const activeSubscriberId = useSelector(selectActiveUserId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (container.status === "idle" && activeSubscriberId) {
      dispatch(loadContainers(activeSubscriberId));
    }
  }, [container.status, activeSubscriberId]);

  let content;
  if (container.status === "idle" || container.status === "loading") {
    return <CircularProgress size={15} />;
  } else if (container.status === "succeeded") {
    content = (
      <Link
        href={container.data.url}
        rel="noopener"
        target="_blank"
        underline="none"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
          color: "#757679",
          fontSize: 14,
        }}
      >
        Powered by
        <img
          src="images/1gateway.png"
          alt="1Gateway logo"
          width={70}
          style={{ marginLeft: "5px" }}
        />
        <Launch fontSize="1rem" />
      </Link>
    );
  } else if (container.status === "failed") {
    content = (
      <Typography variant="caption" sx={{ paddingLeft: 3, paddingTop: 2 }}>
        Failed to load link to 1Gateway.
      </Typography>
    );
  }

  return <>{content}</>;
}

export default LinkTo1Gateway;

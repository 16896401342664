import React from "react";
import Slider from "react-slick";
import { Box } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const pics = [
  "team1",
  "team3",
  "team4",
  "team5",
  "team2",
  "team8",
  "team6",
  "team7",
];

function TeamCarousel() {
  const settings = {
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1920, // Adjust this value as needed
        settings: {
          slidesToShow: 6, // Adjust the number of slides to show
          slidesToScroll: 1,
          infinite: true,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        width: "100%",
        justifyContent: "center",
        overflow: "clip",
        alignItems: "center",
        pt: "2rem",
      }}
    >
      <Slider {...settings}>
        {pics.map((pic, index) => (
          <div key={pic}>
            <Box
              sx={{
                width: 200,
                height: 200,
                backgroundImage: `url(images/${pic}.jpeg)`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                m: "auto",
                borderRadius: "2rem 0rem",
              }}
            />
          </div>
        ))}
      </Slider>
    </Box>
  );
}

export default TeamCarousel;

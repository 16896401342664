import { Box, Link, Typography, Grid } from "@mui/material";
import React from "react";
import { primaryBlue } from "../utils/jsutils";
import { useLocation, useNavigate } from "react-router";
import { primaryBgc } from "../utils/jsutils";

//move into it's own page "1bonding/privacy-policy"

function WebFooter() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLinkClick = (e) => {
    if (location.pathname !== "/") navigate("/");
  };

  return (
    <Box sx={{ backgroundColor: primaryBlue }}>
      <Box>
        <Box
          sx={{
            backgroundColor: primaryBgc,
            display: "flex",
            flexGrow: 1,
            height: {
              xl: 100,
              lg: 100,
              md: 100,
              sm: 50,
              xs: 50,
            },
            borderRadius: "0 0 4em 0",
          }}
        />
      </Box>

      <Grid
        container
        sx={{
          maxWidth: { xl: 800, lg: 800, md: 800, sm: "100%", xs: "100%" },
          pt: 5,
          pb: 5,
          pl: 3,
        }}
        spacing={2}
      >
        <Grid item xs={6} sx={{ alignItems: "center", display: "flex" }}>
          <img alt="1bonding logo" src="images/1bonding_white.svg" width={90} />
        </Grid>
        <Grid item xs={6} sx={{ alignItems: "center", display: "flex" }}>
          <Link
            href="/learn-more"
            onClick={(e) => handleLinkClick(e)}
            sx={{
              color: "white",
              cursor: "pointer",
            }}
          >
            Learn More
          </Link>
        </Grid>
        {/* <Grid item xs={6} sx={{ alignItems: "center", display: "flex" }}>
          <Link
            href="/success-stories"
            onClick={(e) => handleLinkClick(e)}
            sx={{
              color: "white",
              cursor: "pointer",
            }}
          >
            Success Stories
          </Link>
        </Grid> */}
        <Grid item xs={6} sx={{ alignItems: "center", display: "flex" }}>
          <Link
            href="/privacy-policy"
            sx={{
              color: "white",
              fontWeight: 800,
              cursor: "pointer",
            }}
          >
            Privacy Policy
          </Link>
        </Grid>
        <Grid item xs={6} sx={{ alignItems: "center", display: "flex" }}>
          <Link
            href="/#customer-profiles"
            onClick={(e) => handleLinkClick(e)}
            sx={{
              color: "white",
              cursor: "pointer",
            }}
          >
            Benefits
          </Link>
        </Grid>

        <Grid item xs={6} sx={{ alignItems: "center", display: "flex" }}>
          <Typography
            sx={{
              color: "white",
              fontSize: {
                xl: "0.875rem",
                lg: "0.875rem",
                md: "0.875rem",
                sm: "0.775rem",
                xs: "0.775rem",
              },
            }}
          >
            © 2023 1Bonding. All Rights Reserved{" "}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ alignItems: "center", display: "flex" }}>
          <Link
            href="/about-us"
            sx={{
              color: "white",
              cursor: "pointer",
            }}
          >
            About Us
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

export default WebFooter;

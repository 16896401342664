import { configureStore } from "@reduxjs/toolkit";
import bondsReducer from "./features/bonds/bonds";
import auditReducer from "./features/audit/audit";
// import ticketsReducer from "./features/tickets";
import serviceDeskReducer from "./features/servicedesk/servicedesk";
import userReducer from "./features/user/user";
import subscriberReducer from "./features/subscriber/subscriber";
import containerReducer from "./features/container/container";
const store = configureStore({
  reducer: {
    bonds: bondsReducer,
    audit: auditReducer,
    // tickets: ticketsReducer,
    servicedesk: serviceDeskReducer,
    user: userReducer,
    subscriber: subscriberReducer,
    container: containerReducer,
  },
});

export default store;

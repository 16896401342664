import { Grid, Paper, Typography } from "@mui/material";
import { Check, Error } from "@mui/icons-material";
import { green, red, orange, grey, blue } from "@mui/material/colors";

export const transactionStatus = (a) => {
  let content;
  switch (a.errors > 0) {
    case true:
      let text = `${a.errors} ${
        a.errors > 1 ? "issues need" : "issue needs"
      } your attention.`;
      content = (
        <Paper
          aria-label="error-display"
          sx={{
            backgroundColor: red[100],
            display: "flex",
            color: red[800],
            p: 1,
            alignItems: "center",
          }}
        >
          <Error color="error" fontSize="small" sx={{ marginRight: 1 }} />
          <Typography>{text}</Typography>
        </Paper>
      );
      break;
    case false:
      content = <Check color="success" fontSize="small" />;
      break;
    default:
      content = <p>:S</p>;
  }

  return (
    <Grid item xs={12}>
      {content}
    </Grid>
  );
};

function findBondIndexByName(name, bonds) {
  for (var i = 0; i < bonds.length; i++) {
    if (bonds[i].pairedSubscriber.name === name) {
      return i;
    }
  }
  return -1; // Return -1 if the name is not found in the data
}

export function generateAvatarColors(name, bonds) {
  var colors = [
    "#E57373",
    "#BA68C8",
    "#4DB6AC",
    "#FF8A65",
    "#D4E157",
    "#64B5F6",
    "#F06292",
    "#9575CD",
    "#FFD54F",
    "#81C784",
    "#BA68C8",
    "#4DB6AC",
    "#FF8A65",
    "#D4E157",
    "#64B5F6",
    "#F06292",
    "#9575CD",
    "#FFD54F",
    "#81C784",
    "#FFAB40",
    "#9CCC65",
    "#FF7043",
    "#5C6BC0",
    "#FFCA28",
    "#66BB6A",
  ];

  let bondIndex = findBondIndexByName(name, bonds);
  return colors[bondIndex];
}

export const getStatusColor = (status) => {
  switch (status) {
    case "INVITATION SENT":
      return blue[700];
    case "ACCEPTED":
      return green[700];
    case "REJECTED":
      return red[700];
    case "CONFIGURE":
      return orange[700];
    default:
      return grey[500];
  }
};

import api from "./config";

export const BondsApi = {
  getBonds: async function (subscriberId) {
    try {
      let url = "personal/bonds";
      if (subscriberId !== undefined && subscriberId !== null) {
        url += `?subscriberId=${subscriberId}`;
      }

      const response = await api.request({
        url: url,
        method: "GET",
      });
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

import { Box, Typography } from "@mui/material";
import React from "react";

function NotFoundPage() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "calc(100vh - 190px)",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h4">404</Typography>
      <Typography variant="h6">Page not found</Typography>
    </Box>
  );
}

export default NotFoundPage;

import api from "./config";

/**
 * 12/06/23
 * user model
 * {
  email: "dev1@1bonding.com",
  firstName: "d1",
  lastName: "one",
  avatar: null,
  id: "e30d81b08bb34cbfb05722",
  subscribers: [
    {
      name: "dev1",
      email: "dev1@1bonding.com",
      address: "",
      phoneNumber: null,
      type: "CUSTOMER",
      containerId: null,
      id: "15bcbf4e49da43e285a37e",
    },
    {
      name: "Faciligate",
      email: "faciligate@faciligate.com",
      address: "C. de los Álamos, 26",
      phoneNumber: null,
      type: "MSP",
      containerId: null,
      id: "33f83f206778557b05e5",
    },
  ],
};
 */

export const UserApi = {
  registerUser: async function (user) {
    const response = await api.request({
      url: `users/complete-registration`,
      data: user,
      method: "POST",
    });
  },
  getAllUsers: async function (name) {
    const response = await api.request({
      url: `users`,
      method: "GET",
    });
    return response.data;
  },
  getUserById: async function (userId) {
    const response = await api.request({
      url: `users/${userId}`,
      method: "GET",
    });
    return response.data;
  },

  getMe: async function () {
    try {
      const response = await api.request({
        url: `personal/users/me`,
        method: "GET",
      });
      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

import React from "react";
import { useTable, useSortBy, useRowState, useExpanded } from "react-table";
import {
  Table as MaUTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

function TransactionTable({ columns, data }) {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { expanded },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: "timestamp", desc: true }],
      },
      autoResetExpanded: false,
    },
    useSortBy,
    useExpanded,
    useRowState
  );

  // const handleRowClick = (row) => {
  //   row.toggleRowExpanded();
  // };

  return (
    <MaUTable
      {...getTableProps()}
      size="small"
      sx={{ backgroundColor: "white" }}
    >
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell
                {...column.getHeaderProps()}
                color="primary"
                sx={{
                  fontWeight: 600,
                }}
              >
                {column.render("Header")}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <React.Fragment key={i}>
              <TableRow
                {...row.getRowProps()}
                // onClick={() => handleRowClick(row)}
                // sx={{
                //   cursor: "pointer",
                //   backgroundColor: row.isExpanded ? "whitesmoke" : "inherit",
                // }}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: 0,
                      }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </MaUTable>
  );
}

export default TransactionTable;

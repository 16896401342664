import { Box, Container } from "@mui/material";
import React from "react";
import ContactForm from "../components/ContactForm";

function ContactPage() {
  return (
    <Box
      sx={{
        minHeight: {
          md: "calc(87vh - 64px)",
        },
        display: "flex",
        alignItems: "center",
      }}
    >
      <ContactForm />
    </Box>
  );
}

export default ContactPage;

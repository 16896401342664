import { Paper, Typography } from "@mui/material";
import { blueGrey, grey } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";

import React from "react";

function ServiceDeskChip({ servicedesk, handleClick, watchProduct }) {
  const paperSX = {
    paddingBottom: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "inherit",
  };
  const test = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 3,
    cursor: "pointer",
  };

  const ServicedeskLogo = styled(Paper)(({ theme, sd }) => ({
    width: 45,
    height: 45,
    border:
      sd.product === watchProduct
        ? ` 3px solid ${theme.palette.primary.main}`
        : "3px solid transparent",
    "&:hover": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    },
  }));

  return (
    <Paper
      sx={paperSX}
      elevation={0}
      onClick={(e) => handleClick(e, servicedesk)}
    >
      <ServicedeskLogo sx={test} elevation={1} sd={servicedesk}>
        <img
          src={`images/${servicedesk.product.toLowerCase()}.svg`}
          alt="service desk logo"
          width={servicedesk.product === "ServiceNow" ? 70 : 50}
        />
      </ServicedeskLogo>
      <Typography
        variant="subtitle2"
        color="primary"
        sx={{
          marginTop: 1,
          fontWeight: 700,
          textShadow: "white 1px 0 10px",
        }}
      >
        {servicedesk.product}
      </Typography>
    </Paper>
  );
}

export default ServiceDeskChip;

import { findProfileByProfileType } from "../utils/jsutils";
import api from "./config";

let baseUrl = "personal/configurations";

export const ServicedeskApi = {
  getServicedesk: async function (subscriberId) {
    try {
      let url = baseUrl + `?subscriberId=${subscriberId}`;
      const response = await api.request({
        url: url,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.error("Error while fetching servicedesk", error);
      throw error;
    }
  },
  postServicedesk: async function (subscriberId, servicedesk) {
    try {
      let url = baseUrl + `?subscriberId=${subscriberId}`;
      const response = await api.request({
        url: url,
        method: "POST",
        data: servicedesk,
      });
    } catch (error) {
      console.error("Error while posting servicedesk", error);
      throw error;
    }
  },
  updateServicedesk: async function () {
    try {
      const response = await api.request({
        url: baseUrl,
        method: "PATCH",
      });
    } catch (error) {
      console.error("Error while updating servicedesk", error);
      throw error;
    }
  },
  deleteServicedesk: async function (subscriberId) {
    console.log(subscriberId);
    const response = await api.request({
      url: baseUrl,
      method: "DELETE",
      subscriberId: subscriberId,
    });
    return response.data;
  },
  getPlugindef: async function (subscriberId, product) {
    try {
      const response = await api.request({
        url: "personal/proxy",
        method: "POST",
        data: {
          method: "GET",
          endpoint: "/ws/rest/catalog",
          subscriberId: subscriberId,
        },
      });

      //get appropriate profileType (name === profileType && value === endpoint)
      let installedPlugins = response.data.installedPlugins;
      let plugin = installedPlugins.find(
        (plugin) => plugin.plugindef.label === product + " Sender"
      );

      return plugin;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  testServicedesk: async function (payload) {
    try {
      const response = await api.request({
        url: "personal/proxy",
        method: "POST",
        data: {
          method: "POST",
          endpoint: `/ws/rest/plugin/${payload.data.name}/testv4?profile=${payload.data.profiles[0].name}`,
          subscriberId: payload.activeUser,
          data: JSON.stringify({ config: payload.data }),
        },
      });

      return response.data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  saveServicedesk: async function (subscriberId, data) {
    try {
      const response = await api.request({
        url: "personal/proxy",
        method: "POST",
        data: {
          method: "POST",
          endpoint: `/ws/rest/plugin/${data.name}/savev4`,
          subscriberId: subscriberId,
          data: JSON.stringify({ config: data }),
        },
      });

      return response;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

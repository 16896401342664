import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AuditApi } from "../../../apis/auditApi";

export const loadAudit = createAsyncThunk(
  "audit/loadAudit",
  async (activeSubscriberId) => {
    const audit = await AuditApi.getAudit(activeSubscriberId);
    //massage data
    return audit;
  }
);

export const getTransactionsById = createAsyncThunk(
  "audit/getTransactionsById",
  async (payload) => {
    const { activeSubscriberId, id } = payload;

    const transactions = await AuditApi.getTransactionsById(
      activeSubscriberId,
      id
    );
    return transactions;
  }
);

const auditSlice = createSlice({
  name: "audit",
  initialState: {
    data: [],
    status: "idle",
    error: null,
    transactions: { data: [], status: "idle", error: null },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadAudit.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadAudit.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        state.data = action.payload;
      })
      .addCase(loadAudit.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
        state.data = [];
      })
      .addCase(getTransactionsById.pending, (state) => {
        state.transactions.status = "loading";
      })
      .addCase(getTransactionsById.fulfilled, (state, action) => {
        state.transactions.status = "succeeded";
        state.transactions.error = null;
        state.transactions.data = action.payload;
      })
      .addCase(getTransactionsById.rejected, (state, action) => {
        state.transactions.status = "failed";
        state.transactions.error = action.error;
        state.transactions.data = [];
      });
  },
});
// Selects the audit data from the store
export const selectAuditData = (state) => state.audit.data;

// Selects the loading state of the audit data from the store
export const selectAuditStatus = (state) => state.audit.status;

// Selects the error state of the audit data from the store
export const selectAuditError = (state) => state.audit.error;

// Selects the transaction data from the store
export const selectTransactionData = (state) => state.audit.transactions.data;

// Selects the loading state of the transaction data from the store
export const selectTransactionStatus = (state) =>
  state.audit.transactions.status;

// Selects the error state of the transaction data from the store
export const selectTransactionError = (state) => state.audit.transactions.error;

export default auditSlice.reducer;

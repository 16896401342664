import api from "./config";

export const SubscriberApi = {
  getSubscribers: async function () {
    const response = await api.request({
      url: `subscribers`,
      method: "GET",
    });
    return response.data;
  },
  getSubscribersById: async function (subscriberId) {
    const response = await api.request({
      url: `subscribers/${subscriberId}`,
      method: "GET",
    });
    return response.data;
  },
  addSubscriber: async function (userId, subscriber) {
    await api.request({
      url: `subscribers/${userId}`,
      method: "POST",
      data: subscriber,
    });
  },
};

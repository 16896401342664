export const validateEmail = (value) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(value) || "Please enter a valid email address";
};

export const getSubscriberNameById = (subscribers, activeSubscriberId) => {
  const subscriber = subscribers.find(
    (subscriber) => subscriber.id === activeSubscriberId
  );
  return subscriber ? subscriber.name : "";
};

import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Stack,
  Box,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { primaryBgc, primaryBlue, secondary } from "../utils/jsutils";
import { ArrowForward } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

function LearnMorePage() {
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));

  let items = [
    {
      header: "Solution Brief",
      title: "The Service Management Integration Challenge",
      body: "This Solution Brief explores the common challenges faced in service management integration, and provides insights, strategies, and best practices to navigate the complexities.",
      link: "/files/1Bonding Solution Brief.pdf",
      img: "solution-brief.jpg",
    },
    {
      header: "How it Works",
      title: "What makes 1Bonding Unique?",
      body: "Delve deeper into how 1Bonding works to seamlessly exchange tickets, incidents, and service requests across organizations. 1Bonding's unique approach makes Service Management integrations fast, easy, and secure.",
      link: "/files/How 1Bonding Works.pdf",
      img: "how-it-works.jpg",
    },
    {
      header: "Success Story",
      title: "Seamless Cross-Company Collaboration",
      body: "Discover how Acentix leveraged 1Bonding for seamless cross-company collaboration, automating service request exchanges while ensuring data privacy.",
      link: "/files/1Bonding Success Story.pdf",
      img: "success-story.jpg",
    },
  ];

  const handleDownload = (pdfLink) => {
    const anchor = document.createElement("a");
    anchor.href = pdfLink;
    anchor.download = pdfLink.split("/").pop(); // Extract filename from the link
    document.body.appendChild(anchor);
    anchor.click();
    // Remove the anchor from the document body
    document.body.removeChild(anchor);
  };

  return (
    <Box sx={{ backgroundColor: primaryBgc }}>
      <Container>
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pb: 5,
            pt: 5,
          }}
        >
          <Typography
            variant={smallscreen ? "h5" : "h3"}
            sx={{ color: secondary, fontWeight: 800 }}
            align="center"
          >
            Seamless Service Management Integrations
          </Typography>
          <Typography
            variant={smallscreen ? "h5" : "h3"}
            sx={{ color: primaryBlue }}
            align="center"
          >
            <i>Fast. Easy. Secure.</i>
          </Typography>
        </Stack>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            {items.map((item, index) => (
              <Grid
                item
                xs={12}
                md={4}
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: index % 2 === 0 ? "flex-start" : "flex-end",
                  alignItems: "center",
                }}
              >
                <Paper elevation={0} sx={{ maxWidth: 375, overflow: "clip" }}>
                  <Box
                    sx={{
                      height: 150,
                      backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url(images/${item.img})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></Box>
                  <Stack sx={{ p: 3, mt: -5 }}>
                    <Stack sx={{ minHeight: 300 }}>
                      <Typography variant="h6" style={{ color: primaryBlue }}>
                        <i>{item.header}</i>
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h3"
                        style={{ fontWeight: 700, color: secondary }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ marginTop: "10px", color: primaryBlue }}
                      >
                        {item.body}
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        endIcon={<ArrowForward />}
                        style={{ marginTop: "10px", color: primaryBlue }}
                        onClick={() => handleDownload(item.link)}
                      >
                        {item.header}
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>
    </Box>
  );
}

export default LearnMorePage;

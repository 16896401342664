import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BondsApi } from "../../../apis/bondsApi";

export const loadBonds = createAsyncThunk(
  "bonds/loadBonds",
  async (subscriberId) => {
    const bonds = await BondsApi.getBonds(subscriberId);
    return bonds;
  }
);

const bondsSlice = createSlice({
  name: "bonds",
  initialState: {
    data: [],
    status: "idle",
    selectedBond: null,
    error: null,
  },
  reducers: {
    selectBond: (state, action) => {
      state.selectedBond = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadBonds.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadBonds.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(loadBonds.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectBondsData = (state) => state.bonds.data;
export const selectBondsStatus = (state) => state.bonds.status;
export const selectedBondData = (state) => state.bonds.selectedBond;
export const { selectBond } = bondsSlice.actions;

export default bondsSlice.reducer;

import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Stack } from "@mui/material";
import FormField from "../../../components/FormField";
import { ServicedeskApi } from "../../../apis/servicedeskApi";
import { useSelector } from "react-redux";
import { selectActiveUserId } from "../user/user";
import { findProfileByProfileType } from "../../../utils/jsutils";

function ServiceDeskConfiguration({ product, handlePlugindefChange, methods }) {
  const [loading, setLoading] = useState(true);
  const [plugindef, setPlugindef] = useState(null);
  const activeSubscriberId = useSelector(selectActiveUserId);

  useEffect(() => {
    const fetchPluginDefinition = async (product) => {
      let plugin = await ServicedeskApi.getPlugindef(
        activeSubscriberId,
        product
      );
      setPlugindef(plugin.plugindef);
      handlePlugindefChange(plugin);
      setLoading(false);
    };

    fetchPluginDefinition(product);
  }, []);

  useEffect(() => {
    // Update the form values when plugindef changes so next button is disabled immediately if there are undefined field values
    if (plugindef && Object.keys(methods.getValues()).length === 1) {
      console.log(plugindef);
      let profile = findProfileByProfileType(plugindef.profiles);

      profile.fields
        .filter((field) => {
          if (!field.access) return -1;
          let access = Number(field.access);
          return access < 3;
        })
        .forEach((field) => {
          methods.setValue(field.name, undefined); // Use the field name and default value from plugindef
        });
    }
  }, [plugindef, methods]);

  let content;

  if (loading) {
    content = (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 400,
          flexDirection: "column",
          flexGrow: 1,
          width: "100%",
        }}
      >
        <img
          src="images/1bonding_icon.svg"
          width={55}
          style={{
            animation: "spin 0.8s linear infinite",
          }}
        />
        <Typography variant="caption" sx={{ m: 2 }}>
          Loading...
        </Typography>
      </Box>
    );
  } else if (!plugindef || !findProfileByProfileType(plugindef.profiles)) {
    return <Typography>Error</Typography>;
  } else {
    return (
      <Box
        sx={{
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          width: "90%",
          maxWidth: 700,
        }}
      >
        <Stack
          sx={{
            minHeight: 300,
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          {findProfileByProfileType(plugindef.profiles)
            .fields.filter((field) => {
              if (!field.access) return -1;
              let access = Number(field.access);
              return access < 3;
            })
            .map((field) => {
              return (
                <Box key={field.name}>
                  <FormField field={field} />
                </Box>
              );
            })}
        </Stack>
      </Box>
    );
  }
  return (
    <Box
      id="servicedesk-configuration"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
      }}
    >
      {content}
    </Box>
  );
}

export default ServiceDeskConfiguration;

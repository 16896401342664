import React from "react";
import { Avatar, Tooltip, Typography } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import { generateAvatarColors } from "../../../utils/bondutil";
import { useSelector } from "react-redux";
import { selectBondsData } from "./bonds";

export default function BondAvatar({ bond, size }) {
  const bonds = useSelector(selectBondsData);
  let width = 34;
  let height = 34;
  let imgWidth = 20;
  if (size) {
    if (size === "lg") {
      width = 50;
      height = 50;
      imgWidth = 28;
    } else if (size === "xl") {
      width = 60;
      height = 60;
      imgWidth = 38;
    }
  }
  if (bond === "Unknown") return null;
  let status = bond.status;
  let statusColor;
  let name = bond.pairedSubscriber.name;

  //(hopefully) TEMPORARY CHECK FOR THIS
  if (status) {
    switch (status) {
      case "ok":
        statusColor = green[500];
        break;
      case "error":
        statusColor = red[500];
        break;
      default:
        statusColor = grey[500];
    }
  } else {
    statusColor = grey[500];
  }

  const avatarStyle = {
    backgroundColor: generateAvatarColors(name, bonds),
    border: "2px solid white",
  };

  return (
    <Tooltip title={name}>
      <Avatar sx={{ width: width, height: height, p: 0.3 }} style={avatarStyle}>
        <img src="images/1bonding_icon_white.svg" width={imgWidth} />
      </Avatar>
    </Tooltip>
  );
}

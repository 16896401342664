import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBond, selectBondsData, selectedBondData } from "./bonds";
import { Button, Box, Paper, Grid, Typography, Stack } from "@mui/material";
import {
  ArrowBack,
  ConfirmationNumber,
  ConfirmationNumberOutlined,
  EmailOutlined,
  Webhook,
} from "@mui/icons-material";
import BondAvatar from "./BondAvatar";
import { generateAvatarColors } from "../../../utils/bondutil";
import BondActions from "./BondActions";
import { selectAuditData } from "../audit/audit";
import { selectServicedeskData } from "../servicedesk/servicedesk";
import WebhookHelpLink from "../../../components/WebhookHelpLink";
import WebhookUrl from "../../../components/WebhookUrl";
import { getStatusChip } from "../../../utils/auditutils";
import { red } from "@mui/material/colors";

function SelectedBondPanel() {
  const selectedBond = useSelector(selectedBondData);
  const auditData = useSelector(selectAuditData);
  const bonds = useSelector(selectBondsData);
  const servicedeskData = useSelector(selectServicedeskData);
  const { bond, pairedSubscriber } = selectedBond;
  const dispatch = useDispatch();

  const deselectBond = () => {
    dispatch(selectBond(null));
  };

  const desiredStatusOrder = [
    "total",
    ...Object.keys(selectedBond.bond.numRecords).filter(
      (key) => key !== "total"
    ),
  ];

  const userInfo = () => (
    <>
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle2">{pairedSubscriber.email}</Typography>
          {dividerStop()}
          <Typography sx={{ marginLeft: 1 }} variant="subtitle2">
            ID: {pairedSubscriber.id}
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "block",
            lg: "none",
            xl: "none",
          },
        }}
      >
        <Stack direction={"column"}>
          <Typography variant="caption">{pairedSubscriber.email}</Typography>
          <Typography variant="caption">ID: {pairedSubscriber.id}</Typography>
        </Stack>
      </Box>
    </>
  );

  const dividerStop = () => (
    <Typography variant="body1" sx={{ fontWeight: 700, marginLeft: 1 }}>
      ·
    </Typography>
  );

  return (
    <div>
      <Box>
        <Button
          onClick={deselectBond}
          endIcon={<ArrowBack color="secondary" />}
          sx={{ fontWeight: 800, marginBottom: 1 }}
          size="small"
        >
          View all bonds
        </Button>
      </Box>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          overflow: "clip",
          borderLeft: `10px solid ${generateAvatarColors(
            pairedSubscriber.name,
            bonds
          )}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              p: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "block",
                    xl: "block",
                  },
                }}
              >
                <BondAvatar bond={selectedBond} size="lg" />
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "block",
                    lg: "none",
                    xl: "none",
                  },
                }}
              >
                <BondAvatar bond={selectedBond} size="xl" />
              </Box>
              <Stack direction={"column"} sx={{ ml: 1 }}>
                <Typography sx={{ fontWeight: 600 }} variant="h6">
                  {pairedSubscriber.name}
                </Typography>
                {userInfo()}
              </Stack>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                flexGrow: 1,
              }}
            >
              <BondActions status={bond.status} />
            </Box>
          </Box>
          <Grid container spacing={0} sx={{ pl: 2, pr: 2, pb: 2 }}>
            <Grid item xs={4}>
              <Stack sx={{ mt: 0.5 }}>
                {desiredStatusOrder.map((key) => (
                  <Box
                    key={key}
                    sx={{ display: "flex", alignItems: "center", mb: 1 }}
                  >
                    <Box
                      xs={key === "total" ? 4 : 6}
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        ml: key === "total" ? 0 : 4,
                      }}
                    >
                      <Box sx={{ width: 100 }}>
                        {key === "total" ? (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <ConfirmationNumber color="primary" />
                            <Typography
                              sx={{
                                ml: 1,
                              }}
                            >
                              Total
                            </Typography>
                          </Box>
                        ) : (
                          getStatusChip(key)
                        )}
                      </Box>

                      <Typography
                        sx={{
                          fontWeight: 800,
                          ml: 2,
                          color: key === "Error" ? red[700] : "black",
                        }}
                      >
                        {selectedBond.bond.numRecords[key]}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <Stack>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Webhook color="primary" />
                  <Typography
                    variant="body1"
                    sx={{ marginLeft: 1, marginRight: 1 }}
                  >
                    Webhook
                  </Typography>
                </Box>
                {/* <WebhookHelpLink product={servicedeskData.product} /> */}
                <WebhookUrl />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}

export default SelectedBondPanel;

import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Typography,
  Button,
  Box,
  Stepper,
  StepLabel,
  Step,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Divider,
  CardActions,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import {
  Biotech,
  Check,
  Search,
  Settings,
  Close,
  HeadsetMic,
  ArrowBack,
  ArrowForward,
  Save,
} from "@mui/icons-material";
import ServicedeskAutocomplete from "./ServicedeskAutocomplete";
import ServiceDeskConfiguration from "./ServiceDeskConfiguration";
import ServicedeskTester from "./ServicedeskTester";
import Link from "../../../components/Link";
import { blue, blueGrey, green } from "@mui/material/colors";
import { isObjectEmpty, primaryBgc } from "../../../utils/jsutils";
import { useReward } from "react-rewards";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  selectServicedeskTestData,
  selectServicedeskTestStatus,
} from "./servicedesk";
import { ServicedeskApi } from "../../../apis/servicedeskApi";
import { selectActiveUserId } from "../user/user";

const steps = ["Select product", "Configuration details", "Test configuration"];

export default function ServiceDeskStepper({ dialogHandleProductChange }) {
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [plugin, setPlugin] = useState(null);
  const [configuration, setConfiguration] = useState({});
  const { reward } = useReward("rewardId", "confetti");
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const testData = useSelector(selectServicedeskTestData);
  const testStatus = useSelector(selectServicedeskTestStatus);
  const activeUser = useSelector(selectActiveUserId);
  //FORM controls ---------------------------
  const methods = useForm();
  const watchProduct = methods.watch("product");
  const watchedFields = methods.watch();

  const serviceDeskSelector = () => {
    return (
      <Stack
        sx={{
          height: 400,
          justifyContent: "space-around",
          flexGrow: 1,
          width: "90%",
          maxWidth: 800,
        }}
      >
        <ServicedeskAutocomplete watchProduct={watchProduct} />
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "end",
            alignItems: "top",
          }}
        >
          <Typography variant="subtitle1" sx={{ marginRight: 1 }}>
            Can't find what you need?{" "}
          </Typography>
          <Typography variant="subtitle1">
            <Link href="/contact-us">We're here to help.</Link>
          </Typography>
        </Box>
      </Stack>
    );
  };

  const handlePlugindefChange = (data) => {
    setPlugin(data);
  };

  const serviceDeskConfiguration = () => {
    return (
      <ServiceDeskConfiguration
        product={watchProduct}
        handlePlugindefChange={handlePlugindefChange}
        methods={methods}
      />
    );
  };

  const serviceDeskTester = () => (
    <ServicedeskTester
      plugin={plugin}
      methods={methods}
      handleTestDataChange={handleTestDataChange}
    />
  );

  const handleTestDataChange = (data) => setConfiguration(data);

  //DIALOG --------------------------------
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
    methods.reset();
  };

  //STEPPER --------------------------------
  const handleNext = () => {
    if (activeStep === 2) {
      //handle save
    } else {
    }
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    const newActiveStep = activeStep - 1;
    setActiveStep(newActiveStep);
  };

  const onSubmit = async () => {
    ServicedeskApi.saveServicedesk(activeUser, configuration);
    //TODO handle save configuration
    setOpen(false);
  };

  const CustomStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    zIndex: 1,
    color: blueGrey[800],
    padding: 5,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: blue[100],
      color: blue[900],
    }),
    ...(ownerState.completed && {
      backgroundColor: green[50],
      color: green[700],
    }),
  }));

  function CustomStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <Search />,
      2: <Settings />,
      3: <Biotech />,
    };

    return (
      <CustomStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {completed ? <Check /> : icons[String(props.icon)]}
      </CustomStepIconRoot>
    );
  }

  //FORM --------------------------------
  const handleFormSubmit = (configuration) => {
    if (isObjectEmpty(configuration)) {
      alert("invalid");
    } else {
      setConfiguration(configuration);
      handleNext();
    }
  };
  const handlePublish = (product, config) => {
    //dispatch(setServicedeskConfiguration(config));
    //dispatch(selectServiceDeskProduct(product));
    enqueueSnackbar("Configuration saved", { variant: "success" });
  };

  function checkKeysNotEmpty(obj) {
    for (const key in obj) {
      if (obj[key] === undefined || obj[key] === "") {
        return true;
      }
    }

    return false;
  }

  const nextButtonDisabled = () => {
    switch (activeStep) {
      case 0:
        return watchProduct === undefined;
      case 1:
        if (Object.keys(watchedFields).length === 1) {
          return true;
        }
        return checkKeysNotEmpty(watchedFields);
      case 2:
        return false;
      default:
        return true;
    }
  };

  const renderStepCount = (step) => {
    switch (step) {
      case 0:
        return serviceDeskSelector();
      case 1:
        return serviceDeskConfiguration();
      case 2:
        return serviceDeskTester();
      default:
        return null;
    }
  };

  const nextButtonText = () => {
    switch (activeStep) {
      case 1:
        return "Test";
      case 2:
        return "Save";
      default:
        return "Next";
    }
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        sx={{
          marginTop: 1,
          fontWeight: 800,
          fontSize: !smallscreen ? "1.5rem" : "1rem",
        }}
        color="secondary"
        variant="text"
      >
        Let's fix that
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"lg"}>
        <DialogTitle
          color="primary"
          sx={{
            fontWeight: 800,
            display: "flex",
            alignItems: "center",
            fontSize: smallscreen ? 16 : 22,
          }}
        >
          <HeadsetMic
            color="primary"
            sx={{ marginRight: 2, fontSize: smallscreen ? 25 : 40 }}
          />
          {watchProduct
            ? watchProduct + " configuration"
            : "Service desk configuration"}
        </DialogTitle>
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
          <Stepper
            activeStep={activeStep}
            orientation={smallscreen ? "vertical" : "horizontal"}
            sx={{
              color: activeStep === steps.length ? green[500] : null,
              width: "90%",
            }}
          >
            {steps.map((step, i) => (
              <Step key={step}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                  <Typography
                    sx={{
                      fontWeight: activeStep === i ? 700 : 500,
                      color: blueGrey[900],
                    }}
                  >
                    {step}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <FormProvider {...methods}>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Card elevation={0} sx={{ borderRadius: 2 }}>
                <CardContent
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: primaryBgc,
                  }}
                >
                  {renderStepCount(activeStep)}
                </CardContent>
                <CardActions sx={{ justifyContent: "space-between" }}>
                  <Button
                    startIcon={activeStep === 0 ? null : <ArrowBack />}
                    onClick={handleBack}
                  >
                    {activeStep === 0 ? "" : "Back"}
                  </Button>
                  <Button
                    endIcon={activeStep === 2 ? <Save /> : <ArrowForward />}
                    disabled={nextButtonDisabled()}
                    onClick={
                      activeStep === 2
                        ? methods.handleSubmit(onSubmit)
                        : handleNext
                    }
                  >
                    {nextButtonText()}
                  </Button>
                </CardActions>
              </Card>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ServicedeskApi } from "../../../apis/servicedeskApi";

const initialState = {
  configuration: {},
  test: {
    status: "idle",
    error: null,
    data: null,
  },
  status: "idle",
  error: null,
};

export const loadServicedesk = createAsyncThunk(
  "servicedesk/loadServicedesk",
  async (payload) => {
    const sd = await ServicedeskApi.getServicedesk(payload);
    return sd;
  }
);

export const testServicedesk = createAsyncThunk(
  "servicedesk/testServicedesk",
  async (payload) => {
    const res = await ServicedeskApi.testServicedesk(payload);
    return res;
  }
);

export const saveServicedesk = createAsyncThunk(
  "servicedesk/saveServicedesk",
  async (servicedesk) => {
    await ServicedeskApi.postServicedesk(servicedesk);
    return servicedesk;
  }
);

export const deleteServicedesk = createAsyncThunk(
  "servicedesk/deleteServicedesk",
  async (payload) => {
    const response = await ServicedeskApi.deleteServicedesk(payload);
    return response.data;
  }
);

const serviceDeskSlice = createSlice({
  name: "servicedesk",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadServicedesk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadServicedesk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.configuration = action.payload;
      })
      .addCase(loadServicedesk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(saveServicedesk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(saveServicedesk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.configuration = action.payload.formData;
      })
      .addCase(saveServicedesk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(testServicedesk.pending, (state) => {
        state.test.status = "loading";
      })
      .addCase(testServicedesk.fulfilled, (state, action) => {
        state.test.status = "succeeded";
        state.test.data = action.payload;
      })
      .addCase(testServicedesk.rejected, (state, action) => {
        state.test.status = "failed";
        state.test.error = action.error.message;
        state.test.data = null;
      })
      .addCase(deleteServicedesk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteServicedesk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.configuration = {};
      })
      .addCase(deleteServicedesk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { startVerification, finishVerification, failVerification } =
  serviceDeskSlice.actions;

export const selectServicedeskStatus = (state) => state.servicedesk.status;
export const selectServicedeskData = (state) => state.servicedesk.configuration;
export const selectServicedeskTestStatus = (state) =>
  state.servicedesk.test.status;
export const selectServicedeskTestData = (state) => state.servicedesk.test.data;
export default serviceDeskSlice.reducer;

import React, { useState } from "react";
import { Add } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Button,
  TextField,
} from "@mui/material";
import NewBondForm from "./NewBondForm";
import { blueGrey } from "@mui/material/colors";

export default function NewBondDialog() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        size="small"
        sx={{
          color: blueGrey[800],
          textTransform: "none",
          fontWeight: 600,
        }}
        endIcon={<Add color="secondary" />}
      >
        Create bond
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontWeight: 700, color: blueGrey[800] }}>
          New bond
        </DialogTitle>
        <DialogContent>
          <NewBondForm handleClose={handleClose} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { isObjectEmpty } from "../../../utils/jsutils";

function LoginForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      email: "user_faciligate@faciligate.com",
      password: "1bonding123!",
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const servicedeskConfiguration = useSelector(
    (state) => state.servicedesk.configuration
  );

  async function onSubmit(payload, e) {
    e.preventDefault();
    setSubmitting(true);

    try {
      setSubmitting(false);
      enqueueSnackbar("Login successful", {
        variant: "success",
        autoHideDuration: 1000,
      });
    } catch (error) {
      console.log("Error:", error);
    }

    if (isObjectEmpty(servicedeskConfiguration)) {
      navigate("/servicedesk");
    } else {
      navigate("/");
    }
  }

  function handleShowPasswordToggle() {
    setShowPassword(!showPassword);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container sx={{ p: 2 }} spacing={4}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          alignItems="center"
        >
          <img src="images/1bonding_blue.svg" alt="1bonding logo" width={200} />
          <Typography variant="h5" sx={{ fontWeight: 500, marginTop: 1 }}>
            Welcome back!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register("email", { required: true })}
            error={!!errors.email}
            helperText={errors.email ? "Email is required" : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            fullWidth
            margin="normal"
            {...register("password", { required: true })}
            error={!!errors.password}
            helperText={errors.password ? "Password is required" : ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPasswordToggle}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={submitting}
            fullWidth
          >
            Login
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          {" "}
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="body1">New to 1Bonding?</Typography>
            <Link
              href="register"
              sx={{ marginLeft: 1, color: "black", fontWeight: 700 }}
            >
              Sign up.
            </Link>
          </span>
        </Grid>
      </Grid>
    </form>
  );
}

export default LoginForm;

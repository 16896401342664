import axios from "axios";
import keycloak from "./keycloak";

let baseUrl = window.REACT_APP_URL;
if (!baseUrl || baseUrl === undefined) {
  baseUrl = "/api/v1/";
}
// Create a new instance of the axios library with a baseURL
const api = axios.create({
  baseURL: baseUrl,
});

// Define an error handler to log all errors except 401 errors
const errorHandler = (error) => {
  const statusCode = error.response?.status;

  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

// Add an interceptor to the "api" axios instance to add the Authorization header
// with the bearer token obtained from Keycloak
api.interceptors.request.use(async (config) => {
  // Get the access token from Keycloak
  // If the access token is available, add it to the headers
  const token = keycloak.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

// Add an interceptor to the "api" axios instance to handle errors
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});

// Export the "api" axios instance
export default api;

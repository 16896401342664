import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Container,
  Paper,
  useMediaQuery,
  useTheme,
  Stack,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { primaryBgc } from "../utils/jsutils";

function ContactForm() {
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [hbsptLoading, setHbpstLoading] = useState(true);

  useEffect(() => {
    //loads hubspot contact-us form
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "139526139",
          formId: "f6914af1-919e-47a0-b94a-b3c9d69f58f2",
          target: "#hubspotForm",
        });

        setHbpstLoading(false);
      }
    });
  }, []);

  return (
    <div
      style={{
        backgroundColor: primaryBgc,
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: smallscreen ? "1rem" : "2rem",
            pb: smallscreen ? "1rem" : "2rem",
          }}
        >
          <Stack
            id="header-form-container"
            direction={{
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            }}
            spacing={{ xs: 1, sm: 2, md: 4, lg: 8, xl: 10 }}
            sx={{
              alignItems: {
                xs: "center",
                sm: "center",
                md: "start",
                lg: "start",
                xl: "start",
              },
            }}
          >
            <Stack
              id="text-img-container"
              direction={{
                xs: "row",
                sm: "row",
                md: "column",
                lg: "column",
                xl: "column",
              }}
              justifyContent={"center"}
              spacing={2}
            >
              <Stack
                id="text-stack"
                sx={{
                  pt: "2rem",
                  pb: { xs: 3, sm: 0, md: 0, lg: 0, xl: 0 },
                  position: "relative",
                  width: { xs: "90%", sm: 300, md: 380, lg: 380, xl: 380 },
                }}
              >
                <Typography
                  color={"secondary"}
                  sx={{
                    fontWeight: 800,
                    mb: 1,
                    fontSize: {
                      xs: "1.5rem",
                      sm: "1.5rem",
                      md: "2rem",
                      lg: "2rem",
                      xl: "2rem",
                    },
                  }}
                >
                  Schedule a meeting with 1Bonding to get started.
                </Typography>
                <Typography varint="body1">
                  We’re excited to share how 1Bonding can make your service
                  management integrations faster, easier, and more reliable.
                </Typography>
                <Typography varint="body1">
                  Enter your email address and we’ll connect you with the right
                  people to get started.
                </Typography>
              </Stack>
              <Box
                sx={{
                  backgroundImage: "url(images/contact_us.svg)",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: { xs: 0, sm: 300, md: 380, lg: 380, xl: 380 },
                  width: { xs: 0, sm: 300, md: 380, lg: 380, xl: 380 },
                }}
              />
            </Stack>
            <Paper
              elevation={0}
              sx={{
                p: { xs: 2, sm: 4, md: 3, lg: 4, xl: 4 },
                width: { xs: 310, sm: 380, md: 380, lg: 400, xl: 400 },
              }}
            >
              {hbsptLoading && (
                <Box
                  sx={{
                    width: "100%",
                    height: 900,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Stack spacing={2}>
                    {[0, 1, 2, 3, 4, 5].map((item) => (
                      <Stack spacing={0.5} key={item}>
                        <Skeleton variant="rounded" width={50} height={6} />
                        <Skeleton variant="rounded" width={250} height={40} />
                      </Stack>
                    ))}
                  </Stack>
                </Box>
              )}
              {!hbsptLoading && <Box id="hubspotForm"></Box>}
            </Paper>
          </Stack>
          <Box
            sx={{
              backgroundImage: "url(images/contact_us.svg)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: { xs: 300, sm: 0, md: 0, lg: 0, xl: 0 },
              width: { xs: 300, sm: 0, md: 0, lg: 0, xl: 0 },
              display: {
                xs: "block",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          />
        </Box>
      </Container>
    </div>
  );
}

export default ContactForm;

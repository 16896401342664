import React from "react";
import { ContentCopyOutlined } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectServicedeskData } from "../state/features/servicedesk/servicedesk";
import { useSnackbar } from "notistack";
import { selectContainerData } from "../state/features/container/container";

function WebhookUrl() {
  const { enqueueSnackbar } = useSnackbar();

  let webhookUrl =
    "https://faciligate.test.1bonding.com/api/v3/message/send?webhook=Freshdesk&type=JiraWebhookIssue&partner=92dfb5fd91a45fbcd0a9";

  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(webhookUrl);
      enqueueSnackbar("URL copied to clipboard");
    } catch (error) {
      enqueueSnackbar("Failed to copy URL to clipboard");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        borderRadius: "4px",
        border: "1px solid lightgrey",
        maxWidth: 600,
      }}
    >
      <Typography
        variant="caption"
        sx={{
          flex: 1, // Added flex: 1 to allow the text to take remaining space
          p: 1,
          borderRight: "1px solid lightgrey",
          overflow: "hidden", // Added overflow: hidden to prevent text overflow
          textOverflow: "ellipsis", // Added textOverflow: ellipsis to handle long text
          whiteSpace: "nowrap", // Added whiteSpace: nowrap to prevent line breaks
        }}
      >
        {webhookUrl}
      </Typography>
      <IconButton color="primary" onClick={handleCopyUrl} variant="contained">
        <ContentCopyOutlined fontSize="small" color="primary" />
      </IconButton>
    </Box>
  );
}

export default WebhookUrl;

import api from "./config";

let baseUrl = "personal/invitations";

export const InvitationApi = {
  getInvitations: async function () {
    try {
      const response = await api.request({
        url: baseUrl,
        method: "GET",
      });
      let config = JSON.parse(response.data.formData);
      return config;
    } catch (error) {
      console.error("Error while fetching invitations", error);
      throw error;
    }
  },
  postInvitation: async function (invitation) {
    try {
      const response = await api.request({
        url: baseUrl,
        method: "POST",
        data: invitation,
      });
    } catch (error) {
      console.error("Error while sending inviation", error);
      throw error;
    }
  },
};

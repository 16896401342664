import React from "react";
import { primaryBgc } from "../../utils/jsutils";

function SupportedProductsAnimation() {
  const products = [
    "Servicenow",
    "Ivanti",
    "Cherwell",
    "BMC",
    "Jira",
    "Freshdesk",
    "Zendesk",
    "Connectwise",
    "Helpdesk",
    "Servicenow",
    "Ivanti",
    "Cherwell",
    "BMC",
    "Jira",
    "Freshdesk",
    "Zendesk",
    "Connectwise",
    "Helpdesk",
  ];

  const getImageWidth = (product) => {
    switch (product) {
      case "Servicenow":
        return 120;
      case "Connectwise":
        return 130;
      case "Cherwell":
        return 100;
      case "BMC":
        return 70;
      case "Jira":
        return 160;
      case "Freshdesk":
        return 110;
      case "Zendesk":
        return 100;
      case "Ivanti":
        return 70;
      case "Helpdesk":
        return 110;
      default:
        return 100;
    }
  };

  return (
    <div
      id="supportedProducts"
      className="product-wheel"
      style={{ backgroundColor: primaryBgc }}
    >
      <div className="product-slide">
        {products.map((product, index) => (
          <div className="product-item" key={product + index}>
            <img
              src={`images/${product}_alt.svg`}
              width={getImageWidth(product)}
              alt="image logo"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SupportedProductsAnimation;

import { Container } from "@mui/material";
import React from "react";
import LoginForm from "../state/features/user/LoginForm";

function LoginPage() {
  return (
    <Container
      sx={{
        height: `calc(100vh - 75px)`,
        marginTop: { sm: 1, md: 5 },
      }}
      maxWidth="sm"
    >
      <LoginForm />
    </Container>
  );
}

export default LoginPage;

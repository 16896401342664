import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Drawer,
  Divider,
  ListItem,
  List,
  Button,
  Container,
  Typography,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Routes,
  Route,
  Outlet,
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  AccountCircle,
  AccountCircleOutlined,
  ContactSupport,
  ContactSupportOutlined,
  HeadsetMic,
  HeadsetMicOutlined,
  Home,
  HomeOutlined,
  Menu as MenuIcon,
} from "@mui/icons-material";
import ServiceDeskPage from "./pages/ServiceDeskPage";
import AccountPage from "./pages/AccountPage";
import HomePage from "./pages/HomePage";
import NotFoundPage from "./pages/NotFoundPage";
import LoginPage from "./pages/LoginPage";
import ContactPage from "./pages/ContactPage";
import AboutUsPage from "./pages/AboutUsPage";
import "./App.css";
import {
  loginUser,
  selectActiveUserId,
  selectUserData,
  selectUserStatus,
  setActiveSubscriber,
} from "./state/features/user/user";
import ProtectedRoute from "./components/ProtectedRoute";
import { useKeycloak } from "@react-keycloak/web";
import LinkTo1Gateway from "./components/LinkTo1Gateway";
import { primaryBlue } from "./utils/jsutils";
import ProductPage from "./pages/ProductPage";
import SecurityPage from "./pages/SecurityPage";
import {
  loadBonds,
  selectBondsData,
  selectBondsStatus,
} from "./state/features/bonds/bonds";
import {
  loadServicedesk,
  selectServicedeskStatus,
} from "./state/features/servicedesk/servicedesk";
import SubscribersDropdownMenu from "./components/SubscribersDropdownMenu";
import WebFooter from "./components/WebFooter";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import CookieFooter from "./components/CookieFooter";
import SuccessStoriesPage from "./pages/SuccessStoriesPage";
import LearnMorePage from "./pages/LearnMorePage";

const authenticatedLinks = [
  {
    icon: <Home style={{ marginRight: 8 }} />,
    iconAlt: <HomeOutlined style={{ marginRight: 8 }} />,
    text: "Home",
    link: "/",
  },
  {
    icon: <HeadsetMic style={{ marginRight: 8 }} />,
    iconAlt: <HeadsetMicOutlined style={{ marginRight: 8 }} />,
    text: "Service desk",
    link: "/servicedesk",
  },
  {
    icon: <AccountCircle style={{ marginRight: 8 }} />,
    iconAlt: <AccountCircleOutlined style={{ marginRight: 8 }} />,
    text: "Account",
    link: "/account",
  },
  {
    icon: <ContactSupport style={{ marginRight: 8 }} />,
    iconAlt: <ContactSupportOutlined style={{ marginRight: 8 }} />,
    text: "Contact",
    link: "/contact-us",
  },
];
const unauthenticatedLinks = [
  {
    text: "Learn More",
    link: "/learn-more",
  },
  // {
  //   text: "Success Stories",
  //   link: "/success-stories",
  // },
  {
    text: "About Us",
    link: "/about-us",
  },
];

function App(props) {
  //responsive screen size state
  const [mobileOpen, setMobileOpen] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { keycloak, initialized } = useKeycloak();
  let location = useLocation();
  const navigate = useNavigate();
  const drawerWidth = 220;
  const container =
    props.window !== undefined ? () => window().document.body : undefined;
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const dispatch = useDispatch();

  // Selectors
  const user = useSelector(selectUserData);
  const servicedeskStatus = useSelector(selectServicedeskStatus);
  const userStatus = useSelector(selectUserStatus);
  const activeSubscriberId = useSelector(selectActiveUserId);
  const bonds = useSelector(selectBondsData);
  const bondsStatus = useSelector(selectBondsStatus);

  // Load bonds and audits when the component mounts
  useEffect(() => {
    const initializeApp = async () => {
      if (!initialized) {
        setInitializing(true);
      } else {
        setInitializing(false);
      }

      if (initialized && keycloak.authenticated) {
        if (userStatus === "idle") {
          await dispatch(loginUser());
        }

        if (activeSubscriberId && servicedeskStatus === "idle") {
          await dispatch(loadServicedesk(activeSubscriberId));
        }

        if (activeSubscriberId && bondsStatus === "idle") {
          await dispatch(loadBonds(activeSubscriberId));
        }

        setInitializing(false);
      }
    };

    initializeApp();
  }, [
    dispatch,
    user,
    activeSubscriberId,
    keycloak,
    initialized,
    userStatus,
    servicedeskStatus,
    bondsStatus,
  ]);

  useEffect(() => {
    //when a user accesses a link in their SD system, we want the transaction dialog to open
    //keycloak doesn't prompt user to login from search param, so we save it in localStorage
    //transactiondialog responds to item in local storage and deletes it onclose
    let transactionId = searchParams.get("transactionId");
    if (transactionId) {
      localStorage.setItem("transactionId", transactionId);
      searchParams.delete("transactionId");
      handleLogin();
    }
  }, []);

  //scroll to Learn More + Benefits sections
  useEffect(() => {
    // Check if the URL contains a hash
    if (location.hash) {
      // Extract the hash without the '#'
      const id = location.hash.substring(1);
      const productPageElement = document.getElementById(id);
      if (productPageElement) {
        productPageElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const handleLinkClick = (e) => {
    if (location.pathname !== "/") navigate("/");
  };

  const handleLogin = async () => {
    navigate("/", { replace: true });

    try {
      await keycloak.login();
    } catch (error) {
      console.log(error);
    }
  };

  const style = ({ isActive }) => ({
    textDecoration: "none",
    color: isActive ? "white" : primaryBlue,
    backgroundColor: isActive ? primaryBlue : null,
    fontWeight: isActive ? 700 : 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 8,
    marginRight: 8,
    marginTop: 4,
    marginBottom: 8,
    padding: 10,
    borderRadius: ".75rem 0 .75rem 0",
    width: "100%",
  });

  const authenticatedDrawer = (
    <div
      id="authenticatedDrawer wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Toolbar />
      <List>
        {authenticatedLinks.map((link) => (
          <ListItem
            key={link.text}
            disablePadding
            onClick={() => setMobileOpen(false)}
          >
            <NavLink to={link.link} style={style} key={link.text}>
              {({ isActive }) => (
                <>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "auto",
                    }}
                  >
                    {isActive ? link.icon : link.iconAlt}
                    {link.text}
                  </span>
                </>
              )}
            </NavLink>
          </ListItem>
        ))}
      </List>
      {userStatus === "succeeded" && smallscreen && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 2,
          }}
        >
          <SubscribersDropdownMenu />
        </Box>
      )}
      <Divider />
      <LinkTo1Gateway />
    </div>
  );
  const unauthenticatedDrawer = (
    <div
      id="authenticatedDrawer wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Toolbar />
      <List>
        {[{ id: "customer-profiles", title: "Benefits" }].map((item) => (
          <ListItem
            key={item.id}
            disablePadding
            onClick={() => {
              setMobileOpen(false);
              navigate("/");
            }}
          >
            <a
              className="nav_link"
              style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                color: primaryBlue,
                padding: "10px",
                margin: "4px 8px 8px",
                cursor: "pointer",
              }}
              href={"#" + item.id}
            >
              {item.title}
            </a>
          </ListItem>
        ))}

        {unauthenticatedLinks.map((link) => (
          <ListItem
            key={link.text}
            disablePadding
            onClick={() => setMobileOpen(false)}
          >
            <NavLink
              to={link.link}
              style={style}
              className="nav_link"
              key={link.text}
            >
              {({ isActive }) => (
                <>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {isActive ? link.icon : link.iconAlt}
                    {link.text}
                  </span>
                </>
              )}
            </NavLink>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box
        sx={{
          display: {
            lg: "none",
            md: "none",
            sm: "none",
            xs: "flex",
          },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack
          spacing={2}
          sx={{
            paddingTop: 2,
          }}
        >
          <Button
            variant="contained"
            fullWidth
            color="secondary"
            component={NavLink}
            to="/contact-us"
            size="small"
            disableElevation={true}
            sx={{
              marginRight: 1,
              textTransform: "none",
              color: primaryBlue,
              fontWeight: 800,
            }}
          >
            Request a Demo
          </Button>
          <Button
            onClick={handleLogin}
            color="primary"
            variant="contained"
            size="small"
            fullWidth
            disableElevation={true}
            sx={{
              fontWeight: 800,
              textTransform: "none",
            }}
          >
            Login
          </Button>
        </Stack>
      </Box>
    </div>
  );

  //we hide the appbar on register & login pages
  const appBarVisibile = () => {
    switch (location.pathname) {
      case "/login":
        return false;
      default:
        return true;
    }
  };

  return (
    <div id="app-container" style={{ display: "flex" }}>
      {initializing && (
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flexDirection: "column",
          }}
        >
          <img
            src="images/1bonding_icon.svg"
            width={55}
            style={{
              animation: "spin 0.8s linear infinite",
            }}
            alt="1bonding loader"
          />
          <Typography variant="caption" sx={{ m: 2 }}>
            Loading...
          </Typography>
        </Container>
      )}
      {appBarVisibile() && (
        <AppBar
          position={"fixed"}
          elevation={1}
          sx={{
            zIndex: 1210,
            backgroundColor: "white",
          }}
        >
          <Toolbar
            sx={{
              justifyContent: "space-between",
              marginLeft: { sm: 0, md: 2, lg: 4 },
              marginRight: { sm: 0, md: 2, lg: 4 },
              alignItems: "center",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              {!initializing && (
                <IconButton
                  color="primary"
                  aria-label="open nav drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  size={smallscreen ? "sm" : "md"}
                  sx={{
                    mr: {
                      xs: 0.5,
                      sm: 1,
                      md: 1,
                      lg: 2,
                      xl: 2,
                    },
                    display: {
                      xl: keycloak.authenticated ? "block" : "none",
                      lg: keycloak.authenticated ? "block" : "none",
                      md: keycloak.authenticated ? "block" : "none",
                      sm: "block",
                      xs: "block",
                    },
                  }}
                >
                  <MenuIcon fontSize={smallscreen ? "small" : "medium"} />
                </IconButton>
              )}
              <NavLink
                to="/"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <Box sx={{ width: { xs: 110, sm: 130, md: 150 } }}>
                  <img
                    src={"images/1bonding_blue.svg"}
                    alt="logo"
                    style={{ width: "100%", marginTop: 5 }}
                  />
                </Box>
              </NavLink>
              {!initializing && !keycloak.authenticated && (
                <Box
                  sx={{
                    display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
                    justifyContent: "flex-end",
                    width: "100%",
                    marginRight: 5,
                    alignItems: "center",
                  }}
                >
                  <Box className="nav_link" onClick={handleLinkClick}>
                    <a
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                        color: primaryBlue,
                      }}
                      href="#customer-profiles"
                    >
                      Benefits
                    </a>
                  </Box>
                  {unauthenticatedLinks.map((item) => (
                    <NavLink
                      to={item.link}
                      key={item.link}
                      style={{ textDecoration: "none" }}
                    >
                      {({ isActive }) => (
                        <Typography
                          color="primary"
                          class={isActive ? "nav_link_active" : "nav_link"}
                        >
                          {item.text}
                        </Typography>
                      )}
                    </NavLink>
                  ))}
                </Box>
              )}
              {!initializing && !keycloak.authenticated && (
                <Drawer
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                      lg: "none",
                      xl: "none",
                    },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                    },
                  }}
                >
                  {unauthenticatedDrawer}
                </Drawer>
              )}
            </span>
            <span>
              {!initializing && initialized && !keycloak.authenticated && (
                <>
                  <Box
                    sx={{
                      display: {
                        lg: "block",
                        md: "none",
                        sm: "none",
                        xs: "none",
                      },
                      width: 250,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      component={NavLink}
                      to="/contact-us"
                      size="large"
                      disableElevation={true}
                      sx={{
                        marginRight: 1,
                        textTransform: "none",
                        color: primaryBlue,
                        fontWeight: 800,
                      }}
                    >
                      Request a Demo
                    </Button>
                    <Button
                      onClick={handleLogin}
                      color="primary"
                      size="large"
                      sx={{
                        fontWeight: 800,
                        textTransform: "none",
                      }}
                    >
                      Login
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: {
                        lg: "none",
                        md: "block",
                        sm: "none",
                        xs: "none",
                      },
                      width: 220,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      component={NavLink}
                      to="/contact-us"
                      size="medium"
                      disableElevation={true}
                      sx={{
                        marginRight: 1,
                        textTransform: "none",
                        color: primaryBlue,
                        fontWeight: 800,
                      }}
                    >
                      Request a Demo
                    </Button>
                    <Button
                      onClick={handleLogin}
                      color="primary"
                      size="medium"
                      sx={{
                        fontWeight: 800,
                        textTransform: "none",
                      }}
                    >
                      Login
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: {
                        lg: "none",
                        md: "none",
                        sm: "block",
                        xs: "none",
                      },
                      width: 200,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      component={NavLink}
                      to="/contact-us"
                      size="small"
                      disableElevation={true}
                      sx={{
                        marginRight: 1,
                      }}
                    >
                      Request a Demo
                    </Button>
                    <Button onClick={handleLogin} color="primary" size="small">
                      Login
                    </Button>
                  </Box>
                </>
              )}
              {initialized &&
                keycloak.authenticated &&
                userStatus === "succeeded" &&
                !smallscreen && <SubscribersDropdownMenu />}
            </span>
          </Toolbar>
        </AppBar>
      )}
      {!initializing && initialized && keycloak.authenticated && (
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            PaperProps={{
              sx: {
                width: drawerWidth,
              },
            }}
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
              },
            }}
          >
            {authenticatedDrawer}
          </Drawer>
        </Box>
      )}

      {!initializing && (
        <Box component="main" sx={{ width: "100%" }}>
          <Toolbar />
          <Routes>
            <Route element={<Outlet />}>
              <Route path="/" element={<HomePage />} />
              <Route
                path="servicedesk"
                element={
                  <ProtectedRoute>
                    <ServiceDeskPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="account"
                element={
                  <ProtectedRoute>
                    <AccountPage />{" "}
                  </ProtectedRoute>
                }
              />
              {/* <Route path="register" element={<RegistrationPage />} /> */}
              <Route path="login" element={<LoginPage />} />
              <Route path="about-us" element={<AboutUsPage />} />
              <Route path="learn-more" element={<LearnMorePage />} />
              {/* <Route path="success-stories" element={<SuccessStoriesPage />} /> */}
              <Route path="contact-us" element={<ContactPage />} />
              <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
          {!initializing &&
            initialized &&
            !keycloak.authenticated &&
            location !== "privacy-policy" && <WebFooter />}
          <CookieFooter />
        </Box>
      )}
    </div>
  );
}

export default App;

import React from "react";
import { useTable } from "react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Chip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getLatestTransaction, getStatusIcon } from "../../../utils/auditutils";
import BondAvatar from "./BondAvatar";
import { selectAuditData } from "../audit/audit";
import { getBondNameFromId } from "../../../utils/auditutils";
import { timeElapsedFromEpoc } from "../../../utils/jsutils";
import { selectBond } from "./bonds";

const BondsTable = ({ bonds }) => {
  const auditData = useSelector(selectAuditData);
  const dispatch = useDispatch();
  const data = React.useMemo(() => bonds, [bonds]);

  const columns = React.useMemo(
    () => [
      {
        id: 1,
        Header: "Owner",
        Cell: (row) => (
          <Box sx={{ alignItems: "center", display: "flex" }}>
            <BondAvatar bond={row.row.original} />
            <Typography sx={{ ml: 1 }}>
              {row.row.original.pairedSubscriber.name}
            </Typography>
          </Box>
        ),
      },
      {
        id: 3,
        Header: "Connection",
        Cell: (row) => (
          <Chip label={row.row.original.bond.status} size="small" />
        ),
      },
      {
        Header: "Tickets",
        Cell: (row) => {
          let recordInfo = row.row.original.bond.numRecords;
          if (recordInfo) return recordInfo.total;
          return null;
        },
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const handleTableBondSelection = (bond) => {
    dispatch(selectBond(bond));
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table {...getTableProps()} size="small">
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps()}
                  sx={{ fontWeight: 600 }}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                onClick={() => handleTableBondSelection(row.original)}
                sx={{ cursor: "pointer" }}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BondsTable;

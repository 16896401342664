import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SubscriberApi } from "../../../apis/subscriberApi";

export const loadSubscribers = createAsyncThunk(
  "bonds/loadSubscribers",
  async (userId) => {
    const subscribers = await SubscriberApi.getSubscribersById(userId);
    return subscribers;
  }
);

const subscriberSlice = createSlice({
  name: "subscriber",
  initialState: {
    data: {
      email: null,
      name: null,
      id: null,
      type: null,
      address: null,
    },
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadSubscribers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadSubscribers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(loadSubscribers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {} = subscriberSlice.actions;

export default subscriberSlice.reducer;

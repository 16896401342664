import React, { useEffect, useState } from "react";
import { Box, Grid, CircularProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveUserId } from "../user/user";
import { findProfileByProfileType } from "../../../utils/jsutils";
import { Check, Warning } from "@mui/icons-material";
import {
  selectServicedeskTestData,
  selectServicedeskTestStatus,
  testServicedesk,
} from "./servicedesk";
import { green, grey } from "@mui/material/colors";

function ServicedeskTester({ plugin, methods, handleTestDataChange }) {
  const dispatch = useDispatch();

  const testData = useSelector(selectServicedeskTestData);
  const testStatus = useSelector(selectServicedeskTestStatus);

  const activeUser = useSelector(selectActiveUserId);

  useEffect(() => {
    let formData = methods.getValues();

    let endpointProfile = findProfileByProfileType(plugin.plugindef.profiles);
    let endpointData = {};

    // Transfer form data to the selected profile for testing
    endpointProfile.fields.forEach((field) => {
      if (formData[field.name] !== undefined) {
        // field.value = formData[field.name];
        endpointData[field.name] = formData[field.name];
      } else if (field.name && field.value) {
        endpointData[field.name] = field.value;
      }
    });

    // Create the updated profile object
    const updatedProfile = {
      type: endpointProfile.type,
      name: endpointProfile.name,
      data: endpointData,
      // You can add any other properties from endpointProfile that you want to retain
    };

    let data = {
      product: plugin.plugindef.product,
      profiles: [updatedProfile],
      pluginKey: plugin.plugindef.key,
      endpoint: plugin.plugindef.product,
      pluginclass: plugin.plugindef.pluginclass,
      name: plugin.plugindef.label,
      active: true,
    };

    //send data up to stepper to be submitted from form
    handleTestDataChange(data);
    dispatch(testServicedesk({ activeUser, data }));
  }, [plugin]);

  return (
    <Grid sx={{ height: 400, overflow: "scroll" }} container spacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        xl={3}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            p: 1,
            backgroundColor: "white",
            border: `1px solid ${grey[200]}`,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              width: { xl: 50, lg: 50, md: 40, sm: 30, xs: 30 },
              height: { xl: 50, lg: 50, md: 40, sm: 30, xs: 30 },
              backgroundImage: "url(images/1bonding_icon.svg)",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          />
        </Box>
        <Box
          sx={{
            width: 80,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <CircularProgress color="primary" /> */}
          {testStatus === "idle" && <CircularProgress size={20} />}
          {testStatus === "loading" && <CircularProgress size={20} />}
          {testStatus === "succeeded" && (
            <Box
              sx={{
                backgroundColor: green[100],
                borderRadius: 2,
                height: { xl: 50, lg: 50, md: 30, sm: 30, xs: 30 },
                width: { xl: 50, lg: 50, md: 30, sm: 30, xs: 30 },
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Check color="success" />
            </Box>
          )}
          {testStatus === "failed" && <Warning color="warning" />}
        </Box>
        <Box
          sx={{
            p: 1,
            backgroundColor: "white",
            border: `1px solid ${grey[200]}`,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              width: { xl: 50, lg: 50, md: 40, sm: 30, xs: 30 },
              height: { xl: 50, lg: 50, md: 40, sm: 30, xs: 30 },
              backgroundImage: `url(images/${plugin.plugindef.product}.svg)`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={9}
        lg={9}
        xl={9}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {testStatus === "idle" ||
          (testStatus === "loading" && (
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <Typography variant="body1">
                Testing service desk configuration...
              </Typography>{" "}
              <Typography variant="caption">This may take a minute.</Typography>
            </Box>
          ))}
        {testStatus === "succeeded" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <Typography variant="body1">Test was successful!</Typography>
            <Typography variant="caption">
              We'll use this configuration to securely connect to your service
              desk.
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        {testData && (
          <div>
            <pre>{JSON.stringify(testData, null, 2)}</pre>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default ServicedeskTester;

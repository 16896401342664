import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";
import { primaryBlue } from "../utils/jsutils";

function CookieFooter() {
  const [footerRead, setFooterRead] = useState(
    localStorage.getItem("footerRead")
  );

  useEffect(() => {
    if (!footerRead) {
      localStorage.setItem("footerRead", "false");
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("footerRead", "true");
    setFooterRead("true");
  };

  return (
    <Box
      sx={{
        position: "fixed",
        display:
          footerRead === "false" || footerRead === null ? "flex" : "none",
        bottom: 0,
        left: 0,
        right: 0,
        background: "black",
        color: "#fff",
        padding: "10px",
        justifyContent: "space-between",
        alignItems: "center",
        pt: 4,
        pb: 4,
        zIndex: 5147483650,
      }}
    >
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: {
            xl: "row",
            lg: "row",
            md: "column",
            sm: "column",
            xs: "column",
          },
        }}
      >
        <Typography
          sx={{ fontWeight: 800, pb: { xl: 0, lg: 0, md: 2, sm: 2, xs: 2 } }}
        >
          This website uses cookies and third-party services, such as Google
          Analytics. You can find more about data privacy{" "}
          <Link
            to="/privacy-policy"
            style={{ color: "#fff", textDecoration: "underline" }}
          >
            here
          </Link>
          .
        </Typography>
        <Button
          onClick={handleAccept}
          variant="contained"
          style={{
            background: "green",
            color: "#fff",
            cursor: "pointer",
            width: 150,
          }}
        >
          Accept
        </Button>
      </Container>
    </Box>
  );
}

export default CookieFooter;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import SettingsIcon from "@mui/icons-material/Settings";
import { getStatusColor } from "../../../utils/bondutil";
import { grey } from "@mui/material/colors";

const BondActions = ({ status }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = (action) => {
    handleMenuClose();
    switch (action) {
      case "resend":
      case "delete":
      case "configure":
      default:
        console.log(action);
    }
  };

  const getMenuItems = () => {
    switch (status) {
      case "INVITATION SENT":
        return [
          <MenuItem onClick={() => handleButtonClick("resend")} key={"resend"}>
            <SendIcon sx={{ mr: 1 }} />
            Resend
          </MenuItem>,
          <MenuItem onClick={() => handleButtonClick("delete")} key={"delete"}>
            <DeleteIcon sx={{ mr: 1 }} />
            Delete
          </MenuItem>,
        ];
      case "ACCEPTED":
        return [
          <MenuItem
            onClick={() => handleButtonClick("configure")}
            key={"configure"}
          >
            <SettingsIcon sx={{ mr: 1 }} />
            Configure
          </MenuItem>,
          <MenuItem onClick={() => handleButtonClick("delete")} key={"delete"}>
            <DeleteIcon sx={{ mr: 1 }} />
            Delete
          </MenuItem>,
        ];
      case "REJECTED":
        return [
          <MenuItem onClick={() => handleButtonClick("resend")} key={"resend"}>
            <SendIcon sx={{ mr: 1 }} />
            Resend
          </MenuItem>,
          <MenuItem onClick={() => handleButtonClick("delete")} key={"delete"}>
            <DeleteIcon sx={{ mr: 1 }} />
            Delete
          </MenuItem>,
        ];
      case "CONFIGURE":
        return [
          <MenuItem
            onClick={() => handleButtonClick("configure")}
            key={"configure"}
          >
            <SettingsIcon sx={{ mr: 1 }} />
            Configure
          </MenuItem>,
          <MenuItem onClick={() => handleButtonClick("delete")} key={"delete"}>
            <DeleteIcon sx={{ mr: 1 }} />
            Delete
          </MenuItem>,
        ];
      default:
        return null;
    }
  };

  return (
    <Box
      variant="outlined"
      sx={{
        display: "flex",
        alignItems: "center",
        border: `1px solid ${grey[200]}`,
        borderRadius: 1,
        height: 40,
      }}
    >
      <Typography
        variant="body2"
        sx={{ p: 1, color: getStatusColor(status), fontWeight: 600 }}
      >
        {status}
      </Typography>
      <Button
        size="small"
        sx={{ minWidth: 30 }}
        // color="primary"
        aria-label="bond-actions"
        aria-controls="bond-actions-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
      >
        <MoreVertIcon fontSize="small" />
      </Button>
      <Menu
        id="bond-actions-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {getMenuItems()}
      </Menu>
    </Box>
  );
};

export default BondActions;

import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useInView } from "react-intersection-observer";

function ProductAnimationLarge() {
  let colors = ["#67c7cf", "#28afa3", "#68959D", "#afc96d"];

  const [ref1, inView1] = useInView({
    threshold: 0,
  });

  const [ref2, inView2] = useInView({
    threshold: 0,
  });

  const [ref3, inView3] = useInView({
    threshold: 0,
  });

  const [ref4, inView4] = useInView({
    threshold: 0,
  });

  const [ref5, inView5] = useInView({
    threshold: 0,
  });

  return (
    <Box
      sx={{
        display: {
          xl: "flex",
          lg: "flex",
          md: "flex",
          sm: "none",
          xs: "none",
        },
        flexDirection: "column",
      }}
    >
      <Container maxWidth="xl">
        {/*  ######################  GRID 1 ######################  */}

        <Box id="product-animation-1">
          <Grid
            container
            spacing={2}
            sx={{
              height: "100vh",
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                m: "auto",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: {
                      xl: 50,
                      lg: 50,
                      md: 50,
                    },
                    padding: {
                      xl: 5,
                      lg: 5,
                      md: 5,
                    },
                    transform: "scale(1.4)",
                    backgroundColor: "#abcb5d26",
                    borderRadius: "100%",
                    position: "absolute",
                    top: {
                      xl: "-200px",
                      lg: "-200px",
                      md: "-200px",
                    },
                    left: {
                      xl: "300px",
                      lg: "300px",
                      md: "200px",
                    },
                  }}
                >
                  <img src="images/test-sd1.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    width: {
                      xl: 50,
                      lg: 50,
                      md: 50,
                    },
                    padding: {
                      xl: 5,
                      lg: 5,
                      md: 5,
                    },
                    transform: `scale(${inView1 ? 1.4 : 0})`,
                    backgroundColor: "#4d7cbe26",
                    borderRadius: "100%",
                    position: "absolute",
                    top: {
                      xl: "-50px",
                      lg: "-50px",
                      md: "-50px",
                    },
                    left: {
                      xl: "50px",
                      lg: "50px",
                      md: "50px",
                    },
                    transition: "all 0.2s ease-in 0.3s",
                  }}
                >
                  <img src="images/test-logo.svg" width="100%" />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                  pl: "2rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", ml: -1 }}>
                  <Typography
                    sx={{ fontWeight: 600, color: colors[0] }}
                    variant="h3"
                  >
                    1
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 600, pb: 0.5, pl: 2 }}
                    color="primary"
                    variant="h5"
                  >
                    Sign in to 1Bonding
                  </Typography>
                </Box>
                {[
                  "Setting up 1Bonding is easy.  Let’s say you are a Service Provider who wants to integrate your Service Desk with a customer’s.",
                  "First, you’ll select your Service Desk from a long list of supported options.",
                ].map((text, index, arr) => (
                  <React.Fragment key={text}>
                    <Typography
                      color="primary"
                      sx={{ mt: 1 }}
                      variant="h5"
                      paragraph
                    >
                      {text}
                    </Typography>
                    {index === arr.length - 1 && (
                      <Box ref={ref1} sx={{ height: "1px" }}></Box>
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/*  ######################  GRID 2 ######################  */}

        <Box id="product-animation-2">
          <Grid
            container
            spacing={2}
            sx={{
              height: "100vh",
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                m: "auto",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: {
                      xl: inView2 ? 70 : 50,
                      lg: inView2 ? 70 : 50,
                      md: inView2 ? 50 : 50,
                    },
                    backgroundColor: "#abcb5d26",
                    padding: {
                      xl: inView2 ? 10 : 5,
                      lg: inView2 ? 10 : 5,
                      md: inView2 ? 7 : 5,
                    },
                    borderRadius: "100%",
                    position: "absolute",
                    top: {
                      xl: inView2 ? "-260px" : "-200px",
                      lg: inView2 ? "-260px" : "-200px",
                      md: inView2 ? "-190px" : "-200px",
                    },
                    left: {
                      xl: inView2 ? "260px" : "258px",
                      lg: inView2 ? "260px" : "258px",
                      md: inView2 ? "200px" : "258px",
                    },
                    transform: `scale(1.4)`,
                    transition: "all 0.2s ease-in 0.3s",
                  }}
                >
                  <img src="images/test-sd1.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    width: {
                      xl: 60,
                      lg: 60,
                      md: 50,
                    },
                    top: {
                      xl: "-65px",
                      lg: "-65px",
                      md: "-70px",
                    },
                    left: {
                      xl: "235px",
                      lg: "235px",
                      md: "180px",
                    },
                    opacity: inView2 ? 1 : 0,
                    transition: "all 0.2s ease-in 0.3s",
                    zIndex: 100,
                  }}
                >
                  <img src="images/test-shield1.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#4d7cbe26",
                    borderRadius: "100%",
                    position: "absolute",
                    width: {
                      xl: inView2 ? 70 : 50,
                      lg: inView2 ? 70 : 50,
                      md: inView2 ? 50 : 50,
                    },
                    padding: {
                      xl: inView2 ? 10 : 5,
                      lg: inView2 ? 10 : 5,
                      md: inView2 ? 7 : 5,
                    },
                    top: {
                      xl: "-50px",
                      lg: "-50px",
                      md: "-50px",
                    },
                    left: {
                      xl: "50px",
                      lg: "50px",
                      md: "50px",
                    },
                    transform: `scale(1.4)`,
                    transition: "all 0.2s ease-in 0.3s",
                  }}
                >
                  <img src="images/test-logo.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#2aafa326",
                    borderRadius: "100%",
                    position: "absolute",
                    width: {
                      xl: 50,
                      lg: 50,
                      md: 50,
                    },
                    padding: {
                      xl: 5,
                      lg: 5,
                      md: 5,
                    },
                    top: {
                      xl: "200px",
                      lg: "200px",
                      md: "200px",
                    },
                    left: {
                      xl: "350px",
                      lg: "350px",
                      md: "210px",
                    },
                    transform: `scale(${inView3 ? 1.4 : 0})`,
                    transition: "all 1s ease-in",
                  }}
                >
                  <img src="images/test-sd2.svg" width="100%" />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                  pl: "2rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", ml: -1 }}>
                  <Typography
                    sx={{ fontWeight: 600, color: colors[1] }}
                    variant="h3"
                  >
                    2
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 600, pb: 0.5, pl: 2 }}
                    color="primary"
                    variant="h5"
                  >
                    Connect your Service Desk
                  </Typography>
                </Box>
                {[
                  "A 1Bonding Secure Broker will be automatically provisioned, serving as your private broker for secure data exchange. You’ll configure your Secure Broker with your URL and service desk credentials.​",
                  "1Bonding will validate that your configuration is correct.  You’ll always have full control of access to your system and your data.",
                ].map((text, index, arr) => (
                  <React.Fragment key={text}>
                    <Typography
                      color="primary"
                      sx={{ mt: 1 }}
                      variant="h5"
                      paragraph
                    >
                      {text}
                    </Typography>
                    {index === arr.length - 1 && (
                      <Box ref={ref2} sx={{ height: "1px" }}></Box>
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/*  ######################  GRID 3 ######################  */}

        <Box id="product-animation-3">
          <Grid
            container
            spacing={2}
            sx={{
              height: "100vh",
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                m: "auto",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: {
                      xl: 70,
                      lg: 70,
                      md: 50,
                    },
                    backgroundColor: "#abcb5d26",
                    padding: {
                      xl: 10,
                      lg: 10,
                      md: 7,
                    },
                    borderRadius: "100%",
                    position: "absolute",
                    top: {
                      xl: "-260px",
                      lg: "-260px",
                      md: "-200px",
                    },
                    left: {
                      xl: "260px",
                      lg: "260px",
                      md: "200px",
                    },
                    transform: `scale(1.4)`,
                    transition: "all 0.2s ease-in 0.3s",
                  }}
                >
                  <img src="images/test-sd1.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    width: {
                      xl: 60,
                      lg: 60,
                      md: 50,
                    },
                    top: {
                      xl: "-65px",
                      lg: "-65px",
                      md: "-70px",
                    },
                    left: {
                      xl: "235px",
                      lg: "235px",
                      md: "180px",
                    },
                    transition: "all 0.2s ease-in 0.3s",
                    zIndex: 100,
                  }}
                >
                  <img src="images/test-shield1.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#4d7cbe26",
                    borderRadius: "100%",
                    position: "absolute",
                    width: {
                      xl: 70,
                      lg: 70,
                      md: 50,
                    },
                    padding: {
                      xl: 10,
                      lg: 10,
                      md: 7,
                    },
                    top: {
                      xl: "-50px",
                      lg: "-50px",
                      md: "-50px",
                    },
                    left: {
                      xl: "50px",
                      lg: "50px",
                      md: "50px",
                    },
                    transform: `scale(1.4)`,
                    transition: "all 0.2s ease-in 0.3s",
                  }}
                >
                  <img src="images/test-logo.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    width: 50,
                    position: "absolute",
                    top: {
                      xl: "-34px",
                      lg: "-34px",
                      md: "-70px",
                    },
                    left: {
                      xl: "462px",
                      lg: "462px",
                      md: "362px",
                    },
                    transform: {
                      xl: `translate3d(${inView3 ? "0px, 300px, 0px" : "0"})`,
                      lg: `translate3d(${inView3 ? "0px, 300px, 0px" : "0"})`,
                      md: `translate3d(${inView3 ? "0px, 300px, 0px" : "0"})`,
                    },
                    transition: "all 0.2s ease-in 0.3s",
                    zIndex: 100,
                  }}
                >
                  <img src="images/test-invite.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#2aafa326",
                    borderRadius: "100%",
                    position: "absolute",
                    width: {
                      xl: 50,
                      lg: 50,
                      md: 50,
                    },
                    padding: {
                      xl: 5,
                      lg: 5,
                      md: 5,
                    },
                    top: {
                      xl: "210px",
                      lg: "210px",
                      md: "200px",
                    },
                    left: {
                      xl: "300px",
                      lg: "300px",
                      md: "210px",
                    },
                    transform: `scale(${inView3 ? 1.4 : 0})`,
                    transition: "all 0.2s ease-in",
                  }}
                >
                  <img src="images/test-sd2.svg" width="100%" />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                  pl: "2rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", ml: -1 }}>
                  <Typography
                    sx={{ fontWeight: 600, color: colors[2] }}
                    variant="h3"
                  >
                    3
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 600, pb: 0.5, pl: 2 }}
                    color="primary"
                    variant="h5"
                  >
                    Invite Your Customers to Join You on 1Bonding
                  </Typography>
                </Box>
                {[
                  "Now that you’ve set up 1Bonding, you’re ready to integrate your Service Desk (or as we call it, “bond”) with your first customer.",
                  "Simply send them an invitation directly from the 1Bonding portal.​",
                ].map((text, index, arr) => (
                  <React.Fragment key={text}>
                    <Typography
                      color="primary"
                      sx={{ mt: 1 }}
                      variant="h5"
                      paragraph
                    >
                      {text}
                    </Typography>
                    {index === arr.length - 1 && (
                      <Box ref={ref3} sx={{ height: "1px" }}></Box>
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/*  ######################  GRID 4 ######################  */}

        <Box id="product-animation-4">
          <Grid
            container
            spacing={2}
            sx={{
              height: "100vh",
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                m: "auto",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: {
                      xl: 70,
                      lg: 70,
                      md: 50,
                    },
                    backgroundColor: "#abcb5d26",
                    padding: {
                      xl: 10,
                      lg: 10,
                      md: 7,
                    },
                    borderRadius: "100%",
                    position: "absolute",
                    top: {
                      xl: "-260px",
                      lg: "-260px",
                      md: "-200px",
                    },
                    left: {
                      xl: "240px",
                      lg: "240px",
                      md: "200px",
                    },
                    transform: `scale(1.4)`,
                    transition: "all 0.2s ease-in 0.3s",
                  }}
                >
                  <img src="images/test-sd1.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    width: {
                      xl: 60,
                      lg: 60,
                      md: 50,
                    },
                    top: {
                      xl: "-65px",
                      lg: "-65px",
                      md: "-70px",
                    },
                    left: {
                      xl: "235px",
                      lg: "235px",
                      md: "180px",
                    },
                    transition: "all 0.2s ease-in 0.3s",
                    zIndex: 100,
                  }}
                >
                  <img src="images/test-shield1.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#4d7cbe26",
                    borderRadius: "100%",
                    position: "absolute",
                    width: {
                      xl: 70,
                      lg: 70,
                      md: 50,
                    },
                    padding: {
                      xl: 10,
                      lg: 10,
                      md: 7,
                    },
                    top: {
                      xl: "-50px",
                      lg: "-50px",
                      md: "-50px",
                    },
                    left: {
                      xl: "50px",
                      lg: "50px",
                      md: "50px",
                    },
                    transform: `scale(1.4)`,
                    transition: "all 0.2s ease-in 0.3s",
                  }}
                >
                  <img src="images/test-logo.svg" width="100%" />
                </Box>

                <Box
                  sx={{
                    width: {
                      xl: 60,
                      lg: 60,
                      md: 50,
                    },
                    position: "absolute",
                    top: {
                      xl: "135px",
                      lg: "135px",
                      md: "85px",
                    },
                    left: {
                      xl: "240px",
                      lg: "240px",
                      md: "180px",
                    },
                    transition: "all 0.2s ease-in 0.3s",
                    opacity: inView4 ? 1 : 0,
                    zIndex: 100,
                  }}
                >
                  <img src="images/test-shield2.svg" width="100%" />
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#2aafa326",
                    borderRadius: "100%",
                    position: "absolute",
                    width: {
                      xl: inView4 ? 70 : 50,
                      lg: inView4 ? 70 : 50,
                      md: inView4 ? 50 : 50,
                    },
                    padding: {
                      xl: inView4 ? 10 : 5,
                      lg: inView4 ? 10 : 5,
                      md: inView4 ? 7 : 5,
                    },
                    top: {
                      xl: "200px",
                      lg: "200px",
                      md: "160px",
                    },
                    left: {
                      xl: "310px",
                      lg: "310px",
                      md: "250px",
                    },
                    transform: `scale(${
                      inView4 ? 1.4 : 1
                    }) translate3d(-40px, -40px, 0px)`,
                    transition: "all 0.3s ease-in",
                  }}
                >
                  <img src="images/test-sd2.svg" width="100%" />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                  pl: "2rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", ml: -1 }}>
                  <Typography
                    sx={{ fontWeight: 600, color: colors[3] }}
                    variant="h3"
                  >
                    4
                  </Typography>
                  <Typography
                    sx={{ fontWeight: 600, pb: 0.5, pl: 2 }}
                    color="primary"
                    variant="h5"
                  >
                    Your Customer Follows the Same Easy Steps to Join 1Bonding
                  </Typography>
                </Box>
                {[
                  "When your customer accepts your invitation to bond they will go through the same process to connect their service desk to 1Bonding that you did.",
                  "Since your customer will have their own Secure Broker, you don’t need to ask them for Service Desk credentials or API access – 1Bonding eliminates the need to share that information.  Just like you, your customer always stays in control of their data and with whom it is shared.​",
                ].map((text, index, arr) => (
                  <React.Fragment key={text}>
                    <Typography
                      color="primary"
                      sx={{ mt: 1 }}
                      variant="h5"
                      paragraph
                    >
                      {text}
                    </Typography>
                    {index === arr.length - 1 && (
                      <Box ref={ref4} sx={{ height: "1px" }}></Box>
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/*  ######################  GRID 5 ######################  */}

        <Box id="product-animation-5">
          <Grid
            container
            spacing={2}
            sx={{
              height: "100vh",
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                m: "auto",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    transform: inView5 ? "translateX(0)" : "translateX(-100%)",
                    transition: "all 0.4s ease-in 0s",
                    opacity: inView5 ? 1 : 0,
                    pr: 5,
                  }}
                >
                  <img src="images/product_5.svg" width="100%" />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                  pl: "2rem",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{ fontWeight: 600, pb: 0.5 }}
                    color="primary"
                    variant="h5"
                  >
                    That’s it. Now you and your customers can share tickets.
                  </Typography>
                </Box>
                {[
                  "1Bonding is designed to enable as many customer connections as your business requires.  Have a new customer?  Since you are already connected to 1Bonding, your work is already done.  Simply send them an invitation to connect.​",
                  "With 1Bonding, you’ll standardize all your service management integrations and manage them centrally.",
                ].map((text, index, arr) => (
                  <React.Fragment key={text}>
                    <Typography
                      color="primary"
                      sx={{ mt: 1 }}
                      variant="h5"
                      paragraph
                    >
                      {text}
                    </Typography>
                    {index === arr.length - 1 && (
                      <Box ref={ref5} sx={{ height: "1px" }}></Box>
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default ProductAnimationLarge;

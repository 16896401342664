import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Paper,
} from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  ArrowBackIosNew,
  ErrorOutline,
  PublishedWithChanges,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { selectActiveUserId } from "../user/user";
import { AuditApi } from "../../../apis/auditApi";
import { useSnackbar } from "notistack";
import { primaryBgc } from "../../../utils/jsutils";

function TransactionLog({ transaction, handleViewChange }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [log, setLog] = useState(null);
  const [message, setMessage] = useState(null);
  const activeSubscriberId = useSelector(selectActiveUserId);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  //msg retry stuff
  const openConfirmationDialog = () => {
    setShowConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  const handleTransactionRetry = () => {
    openConfirmationDialog();
  };

  const retryTransaction = async () => {
    try {
      await AuditApi.retryTransactionMessage(activeSubscriberId, message);

      enqueueSnackbar("Transaction resubmission successful", {
        variant: "success",
      });

      closeConfirmationDialog();
      handleViewChange("table");
    } catch (error) {
      enqueueSnackbar("Error retrying transaction", {
        variant: "error",
      });
    }
    closeConfirmationDialog();
  };

  //get log
  //get transaction message for retry btn
  useEffect(() => {
    async function fetchTransactionMessage() {
      try {
        const transactionMessage = await AuditApi.getTransactionMessage(
          activeSubscriberId,
          transaction.transactionId.toLowerCase()
        );

        if (typeof transactionMessage === "object") {
          setMessage(transactionMessage);
        } else {
          setMessage(null);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(true);
        console.error("Error fetching transaction message:", error);
      }
    }

    async function fetchTransactionLog() {
      try {
        const transactionLogData = await AuditApi.getTransactionLog(
          activeSubscriberId,
          transaction.transactionId.toLowerCase()
        );
        setLog(transactionLogData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(true);
        console.error("Error fetching transaction log:", error);
      }
    }

    Promise.all([fetchTransactionMessage(), fetchTransactionLog()])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, [transaction]);

  let content;
  if (loading) {
    content = (
      <Box
        sx={{
          width: "100%",
          height: "60vh",
          display: "flex",
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="images/1bonding_icon.svg"
          width={30}
          style={{
            animation: "spin 0.8s linear infinite",
          }}
          alt="1bonding loader"
        />
      </Box>
    );
  } else if (!loading && !error && log) {
    content = (
      <Paper elevation={0} sx={{ p: 2, backgroundColor: primaryBgc }}>
        <div>
          <SyntaxHighlighter
            language="log"
            style={oneLight}
            showLineNumbers
            className="log-text"
          >
            {log}
          </SyntaxHighlighter>
        </div>
      </Paper>
    );
  } else if (!loading && error) {
    content = (
      <Box
        sx={{
          width: "100%",
          height: "60vh",
          display: "flex",
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <ErrorOutline color="error" />
        <Typography sx={{ mt: 1 }}>
          1Bonding encountered an error retrieving the log.
        </Typography>
        <Typography sx={{ mt: 1, fontWeight: 800 }}>
          Please try again.
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sx={{ display: "flex", alignItems: "center", p: 2 }}>
        <IconButton onClick={() => handleViewChange("table")}>
          <ArrowBackIosNew fontSize="small" />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            ml: 1,
            flexGrow: 1,
          }}
        >
          <Typography sx={{ fontWeight: 800, mr: 1 }} variant="h6">
            Log
          </Typography>

          {message && (
            <Button
              sx={{ mr: 1 }}
              size="medium"
              variant="outlined"
              color="primary"
              onClick={handleTransactionRetry}
              // endIcon={<PublishedWithChanges />}
            >
              Resubmit transaction
            </Button>
          )}
        </Box>
        <Dialog open={showConfirmationDialog} onClose={closeConfirmationDialog}>
          <DialogTitle>Confirm Transaction Resubmission</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to resubmit the transaction?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeConfirmationDialog} color="error">
              Cancel
            </Button>
            <Button onClick={retryTransaction} color="primary">
              Resubmit
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Grid item xs={12} sx={{ pl: 1, pr: 1, pb: 1 }}>
        {content}
      </Grid>
    </Grid>
  );
}

export default TransactionLog;

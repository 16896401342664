import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Paper, Typography, Container } from "@mui/material";
import AuditTable from "../audit/AuditTable";
import { red } from "@mui/material/colors";
import { useSnackbar } from "notistack";
import { selectBondsStatus } from "../bonds/bonds";
import { primaryBgc } from "../../../utils/jsutils";
import { selectActiveUserId } from "../user/user";
import { loadAudit } from "./audit";

function AuditPanel() {
  const activeSubscriberId = useSelector(selectActiveUserId);
  const audit = useSelector((state) => state.audit.data);
  const bondsStatus = useSelector(selectBondsStatus);
  const auditError = useSelector((state) => state.audit.error);
  const auditStatus = useSelector((state) => state.audit.status);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (auditStatus === "idle" && activeSubscriberId) {
      dispatch(loadAudit(activeSubscriberId));
    }
    if (auditStatus === "failed") {
      enqueueSnackbar(auditError.message, { variant: "error" });
    }
  }, [audit, auditStatus, auditError, bondsStatus]);

  return (
    <div id="audit-panel">
      <Box sx={{ display: "flex", paddingBottom: 1 }} alignItems="center">
        <Typography
          variant="h5"
          color="primary"
          sx={{ fontWeight: 600, paddingRight: 2 }}
        >
          Tickets
        </Typography>
      </Box>
      <Paper
        sx={{ backgroundColor: primaryBgc, padding: 2, marginBottom: 2 }}
        elevation={0}
      >
        {auditStatus === "loading" && (
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 500,
                flexDirection: "column",
                flexGrow: 1,
                backgroundColor: "white",
              }}
            >
              <img
                src="images/1bonding_icon.svg"
                width={25}
                style={{
                  animation: "spin 0.8s linear infinite",
                }}
              />
              <Typography variant="caption" sx={{ m: 2 }}>
                Loading...
              </Typography>
            </Container>
          </span>
        )}
        {auditStatus === "succeeded" &&
          bondsStatus === "succeeded" &&
          audit.length > 0 && <AuditTable audit={audit} />}
        {auditStatus === "failed" && auditError && (
          <Paper sx={{ backgroundColor: red[100] }}>
            <Typography color="error" variant="body1" sx={{ paddingLeft: 2 }}>
              {auditError?.message}
            </Typography>
          </Paper>
        )}
      </Paper>
    </div>
  );
}

export default AuditPanel;

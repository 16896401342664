import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { primaryBlue } from "../../utils/jsutils";

function ClockAnimation() {
  const theme = useTheme();
  const smscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const mdscreen = useMediaQuery(theme.breakpoints.down("md"));
  const lgscreen = useMediaQuery(theme.breakpoints.down("lg"));
  const xlscreen = useMediaQuery(theme.breakpoints.down("xl"));
  const [highlight, setHighlight] = useState(0);

  useEffect(() => {
    const timer = setInterval(
      () => {
        setHighlight((prevHighlight) => (prevHighlight + 1) % steps.length);
      },
      highlight === 3 ? 5000 : 3000
    ); // Change step every 5 seconds

    return () => clearInterval(timer);
  }, []);

  let circleSize = smscreen
    ? 240
    : mdscreen
    ? 280
    : lgscreen
    ? 400
    : xlscreen
    ? 500
    : 500;

  let colors = ["#67c7cf", "#28afa3", "#68959D", "#afc96d"];

  const steps = [
    "Sign in to 1Bonding",
    "Connect your service desk",
    "Invite your customer to connect",
    "Now you’re connected!",
  ];

  return (
    <div id="clock-animation-container">
      <Box
        sx={{
          display: "flex",
          flexDirection: smscreen ? "column" : "row",
          justifyContent: "center",
          alignItems: "center",
          pt: smscreen ? "2em" : "4em",
        }}
      >
        <Box
          sx={{
            position: "relative",
            height: circleSize,
            width: circleSize,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "55.9%",
              left: "50.15%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              height: { xs: 212, sm: 232, md: 332, lg: 420, xl: 420 },
              width: { xs: 212, sm: 232, md: 332, lg: 420, xl: 420 },
              overflow: "hidden",
              backgroundColor: "transparent",
            }}
          ></Box>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundOrigin: "content-box",
              transition: "all 0.3s ease-in-out",
              WebkitTransition: "0.3s", // Safari/Chrome
              background: `center/contain no-repeat url(images/timer_1.svg)`,
              opacity: highlight === 0 ? 1 : 0,
            }}
          ></Box>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: `center/contain no-repeat url(images/timer_2.svg)`,
              opacity: highlight === 1 ? 1 : 0,
              backgroundOrigin: "content-box",
              transition: "all 0.3s ease-in-out",
              WebkitTransition: "0.3s", // Safari/Chrome
            }}
          ></Box>

          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: `center/contain no-repeat url(images/timer_3.svg)`,
              opacity: highlight === 2 ? 1 : 0,
              backgroundOrigin: "content-box",
              transition: "all 0.3s ease-in-out",
              WebkitTransition: "0.3s", // Safari/Chrome
            }}
          ></Box>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: `center/contain no-repeat url(images/timer_4.svg)`,
              opacity: highlight === 3 ? 1 : 0,
              backgroundOrigin: "content-box",
              transition: "all 0.3s ease-in-out",
              WebkitTransition: "0.3s", // Safari/Chrome
            }}
          ></Box>

          <Box
            sx={{
              position: "absolute",
              top: "70%",
              left: {
                xs: highlight === 1 ? "38%" : "22%",
                sm: highlight === 1 ? "38%" : "30%",
                md: highlight === 1 ? "40%" : "30%",
                lg: highlight === 1 ? "40%" : "30%",
                xl: highlight === 1 ? "40%" : "30%",
              },
              display:
                highlight === -1 || highlight === 1 || highlight === 3
                  ? "block"
                  : "none",
            }}
          >
            <Typography
              color="white"
              sx={{
                fontWeight: 800,
                fontSize: {
                  xl: "2rem",
                  lg: "2rem",
                  md: "1.5rem",
                  sm: "1.2rem",
                  xs: "1.2rem",
                },
              }}
            >
              {highlight === 1 ? "1 hour" : "1 hour 20 mins"}
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "40%",
              left: { xs: "56%", sm: "58%", md: "62%", lg: "60%", xl: "60%" },
              display: highlight === 0 ? "block" : "none",
            }}
          >
            <Typography
              color="white"
              sx={{
                fontWeight: 800,
                fontSize: {
                  xl: "2rem",
                  lg: "2rem",
                  md: "1.5rem",
                  sm: "1.2rem",
                  xs: "1.2rem",
                },
              }}
            >
              15 mins
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "40%",
              left: { xs: "16%", sm: "18%", md: "22%", lg: "20%", xl: "20%" },
              display: highlight === 2 ? "block" : "none",
            }}
          >
            <Typography
              color="white"
              sx={{
                fontWeight: 800,
                fontSize: {
                  xl: "2rem",
                  lg: "2rem",
                  md: "1.5rem",
                  sm: "1.2rem",
                  xs: "1.2rem",
                },
              }}
            >
              5 mins
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: { xs: 350, sm: 360, md: 500, lg: 700, xl: 700 },
            justifyContent: "center",
          }}
        >
          <List>
            {steps.map((step, i) => (
              <ListItem
                sx={{
                  alignItems: "end",
                  cursor: "pointer",
                }}
                key={step}
              >
                <Typography
                  sx={{
                    mt: 1,
                    mr: 2,
                    fontWeight: 800,
                    fontSize: {
                      xs: "2.7rem",
                      sm: "2.5rem",
                      md: "2.6rem",
                      lg: "3.5rem",
                      xl: "3.5rem",
                    },
                    color: highlight === i ? colors[i] : primaryBlue,
                  }}
                >
                  {i + 1}
                </Typography>
                <Typography
                  color="primary"
                  sx={{
                    pb: { xl: 2, lg: 2, md: 1.3, sm: 1.3, xs: 1.5 },
                    fontSize: {
                      xs: "1.1rem",
                      sm: "1.1rem",
                      md: "1.3rem",
                      lg: "1.3rem",
                      xl: "1.3rem",
                    },
                    transform:
                      highlight === i ? "scale(1.1) translateX(15px)" : "none",
                    transition: "all 300ms ease-out",
                    fontWeight: highlight === i ? 800 : 500,
                  }}
                >
                  {step}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </div>
  );
}

export default ClockAnimation;

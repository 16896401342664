import { Error, InfoOutlined } from "@mui/icons-material";
import { Link, Tooltip, Typography, capitalize } from "@mui/material";
import React from "react";

function WebhookHelpLink({ product }) {
  let link = {
    bmc: "https://docs.bmc.com/docs/helixplatform/adding-webhooks-888488763.html",
    jira: "https://developer.atlassian.com/server/jira/platform/webhooks/",
    servicenow:
      "https://www.transposit.com/devops-blog/itsm/creating-webhooks-in-servicenow/",
    freshdesk:
      "https://support.freshdesk.com/en/support/solutions/folders/272646",
    helpdesk:
      "https://api.helpdesk.com/docs#section/Common-notions/Relative-dates-and-datetime-ranges",
    ivanti: "#",
    zendesk:
      "https://developer.zendesk.com/api-reference/webhooks/webhooks-api/webhooks/",
    cherwell:
      "https://help.cherwell.com/bundle/csm_administration_10_help_only/page/content/system_administration/webhooks/csm_webhooks_process.html",
  };
  let content;
  if (link[product] !== undefined) {
    content = (
      <Tooltip
        title={`Click here for help setting up your ${capitalize(
          product
        )} webhook`}
      >
        <Link
          href={link[product]}
          rel="noopener"
          target="_blank"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <InfoOutlined fontSize="small" />
        </Link>
      </Tooltip>
    );
  } else {
    content = (
      <Tooltip title="Please set up your servicedesk to continue.">
        <Error fontSize="small" color="error" />
      </Tooltip>
    );
  }

  return <div>{content}</div>;
}

export default WebhookHelpLink;

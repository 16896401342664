import {
  Container,
  Typography,
  List,
  Link,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import React from "react";
import { primaryBlue, primaryBgc } from "../utils/jsutils";

function PrivacyPolicyPage() {
  const subheader = { pt: 5, fontWeight: 800 };

  return (
    <div id="privacy-policy-container" style={{ backgroundColor: primaryBgc }}>
      <Container
        maxWidth="lg"
        sx={{
          pt: { xl: "10rem", lg: "10rem", md: "7rem", sm: "4rem", xs: "4rem" },
          pb: "10rem",
          color: primaryBlue,
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: 800 }} color="secondary">
          Privacy Policy
        </Typography>
        <Typography variant="h5" sx={subheader}>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph sx={{ pt: 2 }}>
          1Bonding takes the protection of your personal data very seriously. We
          treat personal data confidentially and in accordance with the
          statutory data protection regulations.
        </Typography>
        <Typography variant="body1" paragraph>
          By visiting the Company’s website at{" "}
          <Link href="/">www.1bonding.com</Link> or any of Company’s other
          websites, applications, domain names or other linked pages, or using
          any of our services, you acknowledge that you accept the practices and
          policies outlined in this Privacy Policy.
        </Typography>
        <Typography variant="body1" paragraph>
          When you use our website, various personal data is collected. Personal
          data is data with which you can be personally identified.
        </Typography>
        <Typography variant="body1" paragraph>
          Be aware that data transmission on the Internet, e.g., when
          communicating by e-mail, can have security gaps. Complete protection
          of the data against access by third parties is not possible.
        </Typography>

        <Typography variant="h5" sx={subheader}>
          2. Contact
        </Typography>
        <Typography variant="body1" paragraph sx={{ pt: 1 }}>
          The responsible party for data processing on this website is:
        </Typography>

        <List sx={{ listStyleType: "disc", pl: 4 }} dense disablePadding>
          <ListItem sx={{ display: "list-item" }} disablePadding>
            <ListItemText
              sx={{ fontWeight: 800, fontFamily: "century-gothic" }}
              disableTypography
            >
              1Bonding
            </ListItemText>
          </ListItem>
          <ListItem sx={{ display: "list-item" }} disablePadding>
            <ListItemText>
              Email:
              <Link sx={{ ml: 1 }} href="mailto:privacy@1bonding.com">
                privacy@1bonding.com
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem sx={{ display: "list-item" }} disablePadding>
            <ListItemText>
              Authorized representative: Christian Lewis, Remko Dobber
            </ListItemText>
          </ListItem>
        </List>

        <Typography variant="h5" sx={subheader}>
          3. Links
        </Typography>
        <Typography variant="body1" paragraph sx={{ pt: 2 }}>
          We want to emphasize that any references or links to third-party
          websites provided within our content are beyond our scope of
          responsibility. We do not assume any liability for the content,
          privacy practices, or security of these external websites. Users
          accessing and utilizing such websites do so at their own risk.
        </Typography>
        <Typography variant="h5" sx={subheader}>
          4. Copyrights
        </Typography>
        <Typography variant="body1" paragraph sx={{ pt: 2 }}>
          The copyrights and all other rights to the content, images, photos, or
          other files on the website belong exclusively to the company 1Bonding
          or the specifically named rights holders. For the reproduction of any
          elements, the written consent of the copyright holder must be obtained
          in advance.
        </Typography>
        <Typography variant="h5" sx={subheader}>
          5. SSL, TLS encryption
        </Typography>
        <Typography variant="body1" paragraph sx={{ pt: 2 }}>
          To ensure security and protect the transmission of confidential
          content sent to us as the website operator, this website utilizes SSL
          or TLS encryption. An encrypted connection is identifiable by the
          change in the browser’s address line from ‘http://’ to ‘https://’ and
          the presence of a lock symbol in the browser line.
        </Typography>
        <Typography variant="body1" paragraph>
          By activating SSL or TLS encryption, any data you transmit to us
          becomes unreadable to third parties, thereby enhancing the
          confidentiality and security of your information.
        </Typography>
        <Typography variant="h5" sx={subheader}>
          6. 1Bonding web site data collection
        </Typography>
        <Box sx={{ pl: 4 }}>
          <Typography variant="h5" sx={subheader}>
            6.1 Cookies
          </Typography>
          <Typography variant="body1" paragraph sx={{ pt: 2 }}>
            Some of our internet pages utilize cookies, which are small text
            files stored on your computer and saved by your browser. These
            cookies play a crucial role in making our website more
            user-friendly, effective, and secure. They help enhance your overall
            browsing experience.
          </Typography>
          <Typography variant="body1" paragraph>
            We use “session cookies,” which are automatically deleted after you
            finish your visit to our website. Additionally, there are “other
            cookies” that remain stored on your device until you choose to
            delete them. These persistent cookies enable us to recognize your
            browser and settings when you revisit our website.
          </Typography>
          <Typography variant="body1" paragraph>
            You have the option to configure your browser settings to be
            notified about the use of cookies. You can choose to allow cookies
            on a case-by-case basis, exclude the acceptance of cookies for
            specific situations, or disable them entirely, and you can also
            activate the automatic deletion of cookies upon closing the browser.
            Please be aware that by deactivating cookies, the functionality of
            this website may be limited.
          </Typography>
          <Typography variant="h5" sx={subheader}>
            6.2 Server log data
          </Typography>
          <Typography variant="body1" paragraph sx={{ pt: 2 }}>
            The website provider automatically collects and stores information
            in server log files, which are automatically transmitted to us by
            your browser. The information collected includes:
          </Typography>
          <List sx={{ listStyleType: "disc", pl: 8 }} dense disablePadding>
            {[
              "Your IP address.",
              "Date and time of your visit.",
              "The specific pages you accessed on our website.",
              "The referrer URL (the website from which you came to our site).",
              "Your browser type and version.",
              "Your operating system.",
              "The domain name of your internet service provider.",
              "Any other related data necessary for security purposes.",
            ].map((item) => (
              <ListItem sx={{ display: "list-item" }} disablePadding>
                <ListItemText>{item}</ListItemText>
              </ListItem>
            ))}
          </List>
          <Typography variant="body1" paragraph sx={{ pt: 2 }}>
            This data is used for statistical analysis, to improve the website’s
            functionality and security. It is not linked to any personally
            identifiable information and is treated in accordance with our
            privacy policy.
          </Typography>

          <Typography variant="h5" sx={subheader}>
            6.3 Contact form
          </Typography>
          <Typography variant="body1" paragraph sx={{ pt: 2 }}>
            When you submit inquiries through our contact form, we store the
            data provided in the inquiry form, including your contact details,
            for the sole purpose of processing your inquiry and addressing any
            follow-up questions. Rest assured that we do not disclose this
            information to any third parties without your explicit consent.
          </Typography>
          <Typography variant="body1" paragraph>
            The data you provide in the contact form will be retained by us
            until you request its deletion, or when the purpose of data storage
            no longer applies (e.g., after completing the processing of your
            request). Please note that any mandatory legal provisions,
            particular retention periods, remain unaffected.
          </Typography>
        </Box>

        <Typography variant="h5" sx={subheader}>
          7. Third party data collection
        </Typography>
        <Box sx={{ pl: 4 }}>
          <Typography variant="h5" sx={subheader}>
            7.1 Google web fonts
          </Typography>
          <Typography variant="body1" paragraph sx={{ pt: 2 }}>
            This website uses web fonts provided by Google to ensure a
            consistent and appealing display of text and fonts. When you visit a
            page, your browser automatically loads the necessary web fonts into
            its cache for proper rendering.
          </Typography>
          <Typography variant="body1" paragraph>
            To achieve this, your browser needs to connect to Google’s servers,
            which allows Google to be informed that our website was accessed
            through your IP address. We utilize Google web fonts to ensure a
            consistent and visually appealing presentation of our website.
          </Typography>
          <Typography variant="body1" paragraph>
            If your browser does not support web fonts, a default font is used
            by your computer.
          </Typography>
          <Typography variant="body1" paragraph>
            For more information about Google web fonts, see the following
            links:
          </Typography>
          <Link
            href="https://developers.google.com/fonts/faq"
            rel="noopener noreferrer"
            target="_blank"
            sx={{ wordWrap: "break-word" }}
          >
            <Typography variant="body1" paragraph>
              https://developers.google.com/fonts/faq
            </Typography>
          </Link>
          <Link
            href="https://www.google.com/policies/privacy"
            rel="noopener noreferrer"
            target="_blank"
            sx={{ wordWrap: "break-word" }}
          >
            <Typography variant="body1" paragraph>
              https://www.google.com/policies/privacy
            </Typography>
          </Link>
          <Typography variant="h5" sx={subheader}>
            7.2 Google analytics
          </Typography>
          <Typography variant="body1" paragraph sx={{ pt: 2 }}>
            This website utilizes features from the web analysis service Google
            Analytics. The provider is Google Inc, 1600 Amphitheatre Parkway,
            Mountain View, CA 94043, USA.
          </Typography>
          <Typography variant="body1" paragraph>
            Google Analytics uses so called “Cookies”. These are text files that
            are stored on your computer and enable an analysis of your use of
            the website. The information generated by the cookie about your use
            of this website is usually transmitted to a Google server in the USA
            and stored there.{" "}
          </Typography>
          <Typography variant="h5" sx={subheader}>
            7.2.1 IP anonymization
          </Typography>
          <Typography variant="body1" paragraph sx={{ pt: 2 }}>
            We have activated the IP anonymization function on this website.
            This means that, before being transmitted to the USA, your IP
            address will be shortened by Google within member states of the
            European Union or other contracting states of the Agreement on the
            European Economic Area. Only in exceptional cases will the full IP
            address be sent to a Google server in the USA and shortened there.
          </Typography>
          <Typography variant="body1" paragraph>
            Google will use this information on behalf of the website operator
            to evaluate your use of the website, compile reports on website
            activity, and provide other services related to website activity and
            internet usage. Your browser’s IP address transmitted to Google
            Analytics will not be merged with other data from Google.
          </Typography>
          <Typography variant="h5" sx={subheader}>
            7.2.2 Browser plugin
          </Typography>
          <Typography variant="body1" paragraph sx={{ pt: 2 }}>
            You may refuse the use of cookies by selecting the appropriate
            settings on your browser. However, please note that if you do this
            you may not be able to use the full functionality of this website.
          </Typography>
          <Typography variant="body1" paragraph>
            Furthermore, you can prevent Google from collecting and processing
            data generated by the cookie about your use of the website,
            including your IP address, by downloading and installing the browser
            plugin available at the following link below.
          </Typography>
          <Link
            href="https://tools.google.com/dlpage/gaoptout?hl=en "
            rel="noopener noreferrer"
            target="_blank"
            sx={{ wordWrap: "break-word" }}
          >
            <Typography variant="body1" paragraph>
              https://tools.google.com/dlpage/gaoptout?hl=en
            </Typography>
          </Link>

          <Typography variant="h5" sx={subheader}>
            7.2.3 Data collection objection
          </Typography>
          <Typography variant="body1" paragraph sx={{ pt: 2 }}>
            To prevent the collection of your data by Google Analytics, click on
            the following link. This will set an opt-out cookie, which will
            block data collection during your future visits to this website.
          </Typography>
          <Typography variant="body1" paragraph>
            <Link
              href="https://tools.google.com/dlpage/gaoptout?hl=en "
              rel="noopener noreferrer"
              target="_blank"
              sx={{ wordWrap: "break-word" }}
            >
              https://tools.google.com/dlpage/gaoptout?hl=en
            </Link>
          </Typography>
          <Typography variant="body1" paragraph>
            For more information on how Google Analytics handles user data,
            please see Google’s privacy policy.{" "}
          </Typography>
          <Link
            href="https://support.google.com/analytics/answer/6004245?hl=en"
            rel="noopener noreferrer"
            target="_blank"
            sx={{ wordWrap: "break-word" }}
          >
            <Typography variant="body1" paragraph>
              https://support.google.com/analytics/answer/6004245?hl=en
            </Typography>
          </Link>
        </Box>
        <Typography variant="h5" sx={subheader}>
          Consent
        </Typography>
        <Typography variant="body1" paragraph sx={{ pt: 2 }}>
          By using our website, you hereby consent to this Privacy Policy and
          agree to its terms.
        </Typography>
      </Container>
    </div>
  );
}

export default PrivacyPolicyPage;

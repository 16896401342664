import {
  FileUpload,
  FileDownload,
  ConfirmationNumber,
  Check,
  SyncAlt,
  SyncProblem,
  QuestionMark,
  Warning,
} from "@mui/icons-material";
import { Chip, Typography, Box, Tooltip, capitalize } from "@mui/material";
import { green, grey, lightBlue, orange, red } from "@mui/material/colors";
import { descDateFromEpoc, timeElapsedFromEpoc, timeFromEpoc } from "./jsutils";

export const getOldestTransaction = (transactions) => {
  if (!transactions.length) return null;
  let oldest = transactions[0];
  transactions.forEach((item, i) => {
    if (item.timestamp < oldest.timestamp) oldest = item;
  });
  return oldest;
};

export const getLatestTransaction = (transactions) => {
  if (!transactions.length) return null;
  let latest = transactions[0];
  transactions.forEach((item, i) => {
    if (item.timestamp > latest.timestamp) latest = item;
  });
  return latest;
};

export const getBondNameFromId = (id, bonds) => {
  let pairedSubscribers = bonds.map((b) => b.pairedSubscriber);
  let match = pairedSubscribers.find((p) => p.id === id);

  if (match !== undefined) {
    return match.name;
  } else {
    return "Unknown";
  }
};

export const getBondFromId = (id, bonds) => {
  let match = bonds.find((bond) => bond.pairedSubscriber.id === id);
  if (match !== undefined) {
    return match;
  } else {
    return "Unknown";
  }
};

export const getTicketDirection = (activeSubscriberId, recordSource) => {
  // checks user id + user subscriber id's against recordSource
  if (activeSubscriberId === recordSource) {
    return (
      <Chip
        label="Out"
        icon={<FileUpload sx={{ transform: "rotate(90deg)" }} />}
        sx={{ fontWeight: 700 }}
        size="small"
        variant="outlined"
        color="primary"
      />
    );
  }

  return (
    <Chip
      label="In"
      color="primary"
      icon={<FileDownload sx={{ transform: "rotate(90deg)" }} />}
      sx={{
        fontWeight: 700,
      }}
      size="small"
    />
  );
};

export const stackedTimestamp = (timestamp) => (
  <span style={{ display: "flex", flexDirection: "column" }}>
    <Typography variant="caption">{timeFromEpoc(timestamp)}</Typography>
    <Typography variant="caption" sx={{ fontWeight: 600, color: grey[700] }}>
      {descDateFromEpoc(timestamp)}
    </Typography>
  </span>
);

export const getStatusTextColor = (status) => {
  if (!status) return null;
  switch (status) {
    case "ERROR":
    case "REJECTED":
      return red[800];
    default:
      return grey[800];
  }
};

export const getStatusIcon = (status) => {
  if (!status || status === undefined) return grey[200];

  switch (status.toLowerCase()) {
    case "completed":
    case "synced":
      return <Check color="success" />;
    case "error":
    case "failed":
    case "rejected":
      return <Warning color="error" />;
    case "warning":
      return <SyncProblem color="warning" />;
    case "syncing":
      return <SyncAlt color="primary" />;
    default:
      return <QuestionMark />;
  }
};
export const getStatusColor = (status) => {
  if (!status || status === undefined) return grey[200];
  switch (status.toLowerCase()) {
    case "completed":
    case "synced":
      return green[100];
    case "error":
    case "rejected":
      return red[100];
    case "warning":
      return orange[100];
    case "syncing":
      return lightBlue[100];
    default:
      return grey[200];
  }
};

export const getStatusChip = (status) => {
  let text = status.toLowerCase();
  return (
    <Chip
      icon={getStatusIcon(status)}
      label={capitalize(text)}
      sx={{ fontWeight: 800, backgroundColor: getStatusColor(status) }}
      size="small"
    />
  );
};

export const getUniqueTicketStatuses = (tickets) => {
  let uniqueStatuses = [...new Set(tickets.map((ticket) => ticket.status))];
  return uniqueStatuses;
};

export const getTicketsByBond = (tickets, pairedSubscriber, bonds) => {
  let bondTickets = tickets.filter((ticket) => {
    return getBondNameFromId(ticket.owner, bonds) === pairedSubscriber.name;
  });

  return bondTickets;
};

export const getInterestingTicketFields = (tickets) => {
  let ticketInfo = [
    {
      title: "Total",
      icon: <ConfirmationNumber color="primary" />,
      focus: tickets.length,
    },
    {
      title: "Updated",
      icon: "images/ticket updated.svg",
      focus:
        tickets.length > 0
          ? timeElapsedFromEpoc(getLatestTransaction(tickets).timestamp)
          : "n/a",
    },
  ];

  getUniqueTicketStatuses(tickets).forEach((status) => {
    ticketInfo.push({
      icon: getStatusIcon(status),
      title: status,
      focus: tickets.filter((t) => t.status === status).length,
    });
  });

  return ticketInfo;
};

export const getStyledTicketInfo = (ticket) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }} key={ticket.title}>
      {typeof ticket.icon === "string" ? (
        <img
          alt="last updated icon"
          src={ticket.icon}
          width={24}
          style={{ marginLeft: 2 }}
        />
      ) : (
        ticket.icon
      )}
      <Typography variant="body1" sx={{ marginLeft: 0.5 }}>
        {ticket.title}
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: 600, marginLeft: 0.5 }}>
        {ticket.focus}
      </Typography>
    </Box>
  );
};

export const textTruncator = (text) => {
  return (
    <Tooltip arrow title={text}>
      <Typography variant="body2">{text}</Typography>
    </Tooltip>
  );
};

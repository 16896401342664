import React from "react";
import {
  Box,
  Typography,
  Container,
  Stack,
  Avatar,
  Link,
  Paper,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { primaryBgc, secondaryBlue } from "../utils/jsutils";
import { LinkedIn } from "@mui/icons-material";
import TeamCarousel from "./components/TeamCarousel";

function AboutUsPage() {
  const theme = useTheme();
  const mdscreen = useMediaQuery(theme.breakpoints.down("md"));
  let founders = [
    {
      name: "Chris Lewis",
      title: "Co-Founder and CEO",
      linkedin: "https://www.linkedin.com/in/christian-r-lewis/",
      img: "chris-lewis",
    },
    {
      name: "Remko Dobber",
      title: "Co-Founder and CTO",
      linkedin: "https://www.linkedin.com/in/remkodobber/",
      img: "remko-dobber",
    },
  ];

  return (
    <div
      id="about-us-page"
      style={{ minHeight: "calc(100vh - 64px)", backgroundColor: primaryBgc }}
    >
      <Container
        maxWidth="xl"
        sx={{
          paddingTop: mdscreen ? "2em" : "4em",
          paddingBottom: "4em",
          display: "flex",
          flexDirection: {
            xl: "row",
            lg: "row",
            md: "column-reverse",
            sm: "column-reverse",
            xs: "column-reverse",
          },
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            marginTop: { xl: 0, lg: 0, md: 5, sm: 2, xs: 2 },
          }}
        >
          <Paper
            elevation={0}
            sx={{
              backgroundColor: secondaryBlue,
              p: 3,
              display: "flex",
              height: "fit-content",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                maxWidth: { xl: 450, lg: 400, md: 450, sm: 300, xs: 300 },
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              {founders.map((founder, i) => (
                <Grid
                  key={founder.name}
                  item
                  xs={12}
                  sx={{ justifyContent: "center", alignItems: "end" }}
                >
                  <Stack direction={mdscreen ? "column" : "row"} spacing={2}>
                    <Avatar
                      src={`images/${founder.img}.jpg`}
                      sx={{
                        width: { xl: 200, lg: 160, md: 200, sm: 150, xs: 115 },
                        height: { xl: 200, lg: 160, md: 200, sm: 150, xs: 115 },
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "end",
                      }}
                    >
                      <Typography
                        variant={mdscreen ? "body1" : "h6"}
                        color="white"
                        sx={{ fontWeight: 800 }}
                      >
                        {founder.name}
                      </Typography>
                      <Typography color="white" variant="body1">
                        {founder.title}
                      </Typography>
                      <Link
                        href={founder.linkedin}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <LinkedIn
                          sx={{
                            fontSize: { lg: 30, md: 30, sm: 20, xs: 20 },
                            color: "white",
                          }}
                        />
                      </Link>
                    </Box>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Box>
        <Stack
          direction={"column"}
          sx={{
            width: { xl: 800, lg: 680, md: "80%", sm: "100%", xs: "100%" },
          }}
        >
          <Typography
            variant="body1"
            color="primary"
            sx={{
              fontWeight: 800,
              fontSize: {
                xl: "1.2rem",
                lg: "1.2rem",
                md: "1.1rem",
                sm: "1.1rem",
                xs: "1.1rem",
              },
              mb: mdscreen ? 3 : 5,
            }}
          >
            We're evolving cross-company service management to empower
            businesses to easily connect to one another for better
            collaboration. Our team is dedicated to constructing a global
            service management network for businesses to effortlessly forge
            powerful partnerships across company borders.
          </Typography>
          <Typography
            variant="h5"
            color="secondary"
            sx={{
              fontWeight: 600,
              marginBottom: 1,
              fontSize: {
                xl: "1.5rem",
                lg: "1.5rem",
                md: "1.3rem",
                sm: "1.3rem",
                xs: "1.3rem",
              },
              mb: 1,
            }}
          >
            Our story
          </Typography>
          <Typography
            color="primary"
            paragraph
            sx={{
              fontSize: {
                xl: "1.2rem",
                lg: "1.2rem",
                md: "1.1rem",
                sm: "1.1rem",
                xs: "1.1rem",
              },
            }}
          >
            For decades, we have collaborated with enterprises worldwide,
            offering our expertise in resolving intricate integration
            challenges. Throughout our journey, we have identified a major
            obstacle: the pressing need to modernize Service Management
            Integrations.
          </Typography>
          <Typography
            color="primary"
            paragraph
            sx={{
              fontSize: {
                xl: "1.2rem",
                lg: "1.2rem",
                md: "1.1rem",
                sm: "1.1rem",
                xs: "1.1rem",
              },
            }}
          >
            In response, we built 1Bonding to empower service providers and
            enterprises to seamlessly collaborate quickly, securely, and easily,
            while retaining complete control over the data they share.
          </Typography>
        </Stack>
      </Container>
      <Container maxWidth="md" sx={{ pb: "2rem" }}>
        {" "}
        <Box>
          <Typography
            variant="h5"
            color="secondary"
            sx={{
              fontWeight: 600,
              marginBottom: 1,
              fontSize: {
                xl: "1.5rem",
                lg: "1.5rem",
                md: "1.3rem",
                sm: "1.3rem",
                xs: "1.3rem",
              },
              mb: 1,
            }}
          >
            Our team
          </Typography>
          <Typography
            color="primary"
            paragraph
            sx={{
              fontSize: {
                xl: "1.2rem",
                lg: "1.2rem",
                md: "1.1rem",
                sm: "1.1rem",
                xs: "1.1rem",
              },
            }}
          >
            Headquartered in vibrant Madrid, Spain, we are a seasoned and
            diverse team of developers, integration experts, designers, business
            developers, and marketers, all driven by a shared mission: to
            overcome the challenges of service management integrations.
          </Typography>
          <Typography
            color="primary"
            paragraph
            sx={{
              fontSize: {
                xl: "1.2rem",
                lg: "1.2rem",
                md: "1.1rem",
                sm: "1.1rem",
                xs: "1.1rem",
              },
            }}
          >
            Our approach involves continuous iterations towards success,
            nurturing personal growth, exceeding customer expectations, and
            having fun along the way. Fueled by a collective vision to reshape
            the future of service management integrations, we bring our passion
            and expertise into everything we do.
          </Typography>
        </Box>
      </Container>
      <TeamCarousel />
    </div>
  );
}

export default AboutUsPage;

import React from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Container,
  Button,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { primaryBlue, primaryBgc, secondaryBlue } from "../../utils/jsutils";

function CustomerProfiles() {
  const theme = useTheme();
  const smallscreen = useMediaQuery(theme.breakpoints.down("sm"));
  let customerProfiles = [
    {
      title: "Service Providers",
      img: "service providers.svg",
      subtitle:
        "Make service management integrations a competitive differentiator ",
      content: [
        "Integrate any service desks in hours, not weeks",
        "On-board new customers quickly",
        "Reduce cost of service management integrations",
        "Deliver outstanding customer service",
      ],
      link: "Get in touch",
    },
    {
      title: "It's all about the bond.",
      img: "service providers.svg",
      content: [
        "Transactional efficiency meets strategic collaboration.",
        "On the 1Bonding platform any pair of companies can enter into an agreement to exchange tickets, forming what we call a 'bond'.",
        "A bond is more than a point-to-point integration.  It's a strategic alliance that leverages synergies and optimizes operations across company borders.",
      ],
    },
    {
      title: "Enterprises ",
      img: "enterprises.svg",
      subtitle:
        "Seamlessly connect your internal teams with your IT service providers ",
      content: [
        "Always stay in control of what data you share",
        "Quickly connect with new service providers",
        "Escalate tickets to service providers in one-click using your own service desk",
        "Reduce downtime by accelerating problem resolution",
      ],
      link: "Connect with us",
    },
  ];

  const customerProfileCard = (persona, i) => {
    const color = i === 0 ? "#AFC96D" : "#6EC5D3";

    return (
      <Grid
        key={persona.title}
        item
        xs={12}
        sm={12}
        md={6}
        lg={4}
        xl={4}
        sx={{
          justifyContent: {
            xl: "center",
            lg: "center",
            md: i === 0 ? "end" : "start",
            sm: "center",
            xs: "center",
          },
          display: "flex",
          backgroundColor: primaryBgc,
        }}
      >
        <Paper
          sx={{
            m: 2,
            width: { xs: 350, sm: 500, md: 430, lg: 420, xl: 420 },
            pt: 5,
            height: { xs: "auto", sm: "auto", md: 640, lg: 640, xl: 640 },
            pb: 2,
          }}
          elevation={0}
        >
          <Stack
            sx={{
              display: "flex",
              alignItems: {
                xl: "end",
                lg: "end",
                md: "end",
                sm: "end",
                xs: "center",
              },
              justifyContent: {
                xl: "space-around",
                lg: "space-around",
                md: "space-around",
                sm: "center",
                xs: "center",
              },
              flexDirection: {
                xl: i === 0 ? "row-reverse" : "row",
                lg: i === 0 ? "row-reverse" : "row",
                md: i === 0 ? "row-reverse" : "row",
                sm: i === 0 ? "row-reverse" : "row",
                xs: "column-reverse",
              },
            }}
          >
            <Typography
              variant={smallscreen ? "h5" : "h4"}
              color={color}
              sx={{
                fontWeight: 640,
                width: smallscreen ? 200 : 174,
                marginLeft: {
                  xl: 0,
                  lg: 0,
                  md: 0,
                  sm: i === 0 ? 2 : 0,
                  xs: i === 0 ? 2 : 0,
                },
                marginRight: {
                  xl: 0,
                  lg: 0,
                  md: 0,
                  sm: i === 0 ? 0 : 2,
                  xs: i === 0 ? 0 : 2,
                },
                mt: {
                  xl: 0,
                  lg: 0,
                  md: 0,
                  sm: 2,
                  xs: 2,
                },
                textAlign: {
                  xl: "left",
                  lg: "left",
                  md: "left",
                  sm: "center",
                  xs: "center",
                },
              }}
            >
              {persona.title}
            </Typography>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  backgroundColor: color,
                  borderRadius: smallscreen ? "2rem 0px" : "3rem 0px",
                  width: {
                    xl: 180,
                    lg: 160,
                    md: 180,
                    sm: 180,
                    xs: 140,
                  },
                  height: {
                    xl: 180,
                    lg: 160,
                    md: 180,
                    sm: 180,
                    xs: 140,
                  },
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  zIndex: 3,
                  width: {
                    xl: 180,
                    lg: 160,
                    md: 180,
                    sm: 140,
                    xs: 140,
                  },
                  top: {
                    xl: -74.5,
                    lg: -67,
                    md: -75,
                    sm: -18,
                    xs: -58,
                  },
                  left: {
                    xl: i === 0 ? 0 : -10,
                    lg: i === 0 ? 0 : -10,
                    md: i === 0 ? 0 : -10,
                    sm: i === 0 ? 0 : -10,
                    xs: i === 0 ? 0 : -10,
                  },
                }}
              >
                <img src={`images/${persona.img}`} width={"100%"} />
              </Box>
            </Box>
          </Stack>
          <Grid container sx={{ mt: { xl: 2, lg: 2, md: 2, sm: 2, xs: 2 } }}>
            <Grid
              item
              xs={12}
              sx={{ height: { xl: 100, lg: 100, md: 100, sm: 50, xs: 50 } }}
            >
              <Typography
                variant={smallscreen ? "body1" : "h6"}
                color="primary"
                sx={{
                  fontWeight: 700,
                  textAlign: "center",
                  width: {
                    xl: "80%",
                    lg: "80%",
                    md: "80%",
                    sm: "95%",
                    xs: "95%",
                  },
                  margin: "auto",
                }}
              >
                {persona.subtitle}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List
                dense
                disablePadding
                sx={{
                  p: { xl: 2, lg: 2, md: 2, sm: 5, xs: 2 },
                  height: { xl: 200, lg: 200, md: 200, sm: 150, xs: "100%" },
                }}
              >
                {persona.content.map((text) => (
                  <ListItem
                    key={text}
                    sx={{ alignItems: "center", justifyContent: "center" }}
                    disablePadding
                  >
                    <Box
                      sx={{
                        backgroundColor: color,
                        borderRadius: "3px 0px",
                        minHeight: 10,
                        minWidth: 10,
                        transform: "rotate(40deg)",
                      }}
                    />
                    <ListItemText
                      sx={{
                        marginTop: 1,
                        marginLeft: 2,
                      }}
                      primaryTypographyProps={{
                        color: primaryBlue,
                        fontSize: "1rem",
                        fontWeight: "medium",
                        letterSpacing: 0,
                      }}
                      primary={text}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                size={smallscreen ? "small" : "large"}
                component={NavLink}
                to="/contact-us"
                sx={{
                  textTransform: "none",
                  fontWeight: 800,
                  justifyContent: "center",
                  backgroundColor: color,
                }}
              >
                {persona.link}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  };

  const bondProfileCardLg = (persona) => (
    <Grid
      key={persona.title}
      item
      xl={4}
      lg={4}
      sx={{
        display: {
          xl: "block",
          lg: "block",
          md: "none",
          sm: "none",
          xs: "none",
        },
      }}
    >
      {bondProfileCard(persona)}
    </Grid>
  );
  const bondProfileCardMd = (persona) => (
    <Grid
      key={persona.title}
      item
      xs={12}
      sx={{
        display: {
          xl: "none",
          lg: "none",
          md: "block",
          sm: "block",
          xs: "block",
        },
      }}
    >
      <Container maxWidth="sm">{bondProfileCard(persona)}</Container>
    </Grid>
  );

  const bondProfileCard = (persona) => (
    <Paper
      sx={{
        p: 3,
        pt: 5,
        backgroundColor: secondaryBlue,
        height: { xl: 600, lg: 600, md: 600, sm: "auto", xs: "auto" },
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
      }}
      elevation={0}
      key={persona.title}
    >
      <Typography
        sx={{
          fontWeight: 640,
          fontSize: {
            xl: "2.4rem",
            lg: "2.4rem",
            md: "2.4rem",
            sm: "2rem",
            xs: "1.8rem",
          },
          color: "white",
        }}
      >
        {persona.title}
      </Typography>
      <Stack spacing={2}>
        {persona.content.map((text, i) => (
          <Typography
            color="white"
            sx={{
              fontWeight: i === 0 ? 640 : 500,
              fontSize: "1.2rem",
            }}
            align={i === 0 ? "center" : "left"}
            key={text}
          >
            {text}
          </Typography>
        ))}
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          alt="how it works"
          src="images/all about the bond.svg"
          style={{
            width: 230,
          }}
        />
      </Box>
    </Paper>
  );

  return (
    <Grid
      container
      spacing={{ xs: 2, sm: 3, md: 1, lg: 1, xl: 1 }}
      sx={{
        paddingTop: "2em",
        alignItems: "center",
        paddingLeft: { xl: "6em", lg: 0, md: 0, sm: 0, xs: 0 },
        paddingRight: { xl: "6em", lg: 0, md: 0, sm: 0, xs: 0 },
        backgroundColor: primaryBgc,
      }}
    >
      {bondProfileCardMd(customerProfiles[1])}
      {customerProfiles.map((persona, i) => {
        if (i !== 1) return customerProfileCard(persona, i);
        if (i === 1) return bondProfileCardLg(persona);
      })}
    </Grid>
  );
}

export default CustomerProfiles;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Paper,
  Typography,
  Stack,
  Skeleton,
  Chip,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  capitalize,
} from "@mui/material";
import {
  Check,
  CheckCircle,
  CloseOutlined,
  Dashboard,
  Help,
  Pending,
  SyncAlt,
  Tune,
  ViewList,
  Warning,
} from "@mui/icons-material";
import NewBondDialog from "./NewBondDialog";
import {
  selectBondsStatus,
  loadBonds,
  selectBond,
  selectedBondData,
} from "./bonds";
import { generateAvatarColors, getStatusColor } from "../../../utils/bondutil";
import BondAvatar from "./BondAvatar";
import { primaryBgc, timeElapsedFromEpoc } from "../../../utils/jsutils";
import { selectAuditData } from "../audit/audit";
import {
  getLatestTransaction,
  getBondNameFromId,
  getStatusIcon,
  getUniqueTicketStatuses,
  getTicketsByBond,
  getInterestingTicketFields,
  getStyledTicketInfo,
  getStatusChip,
} from "../../../utils/auditutils";
import { ConfirmationNumber } from "@mui/icons-material";
import BondsTable from "./BondsTable";
import SelectedBondPanel from "./SelectedBondPanel";
import { selectActiveUserId, selectUserStatus } from "../user/user";
import { red } from "@mui/material/colors";

function BondsPanel() {
  const bonds = useSelector((state) => state.bonds.data);
  const bondsStatus = useSelector(selectBondsStatus);
  const selectedBond = useSelector(selectedBondData);
  const auditData = useSelector(selectAuditData);
  const activeSubscriberId = useSelector(selectActiveUserId);
  const userStatus = useSelector(selectUserStatus);
  const dispatch = useDispatch();

  const [displayMode, setDisplayMode] = useState("cards");

  const handleAddBond = () => {
    console.log("add bond");
  };

  const toggleDisplayMode = () => {
    if (displayMode === "cards") {
      setDisplayMode("table");
    } else {
      setDisplayMode("cards");
    }
  };

  useEffect(() => {
    if (bondsStatus === "idle" && userStatus === "succeeded") {
      dispatch(loadBonds(activeSubscriberId));
    }
  }, [selectedBond, bondsStatus, bonds, userStatus]);

  const handleSelectBond = (bond) => {
    if (selectedBond) {
      if (selectedBond.bond.id === bond.bond.id) {
        dispatch(selectBond(null));
        return;
      } else {
        dispatch(selectBond(bond));
      }
    } else {
      dispatch(selectBond(bond));
    }
  };

  const handleBondsTitleClick = () => {
    dispatch(selectBond(null)); // Reset the selected bond when Bonds is clicked
  };

  const getBondStatusIcon = (status) => {
    switch (status) {
      case "INVITATION SENT":
      case "PENDING":
        return <Pending color="disabled" />;
      case "ACCEPTED":
        return <CheckCircle color="success" />;
      case "REJECTED":
        return <CloseOutlined color="error" />;
      default:
        return <Help />;
    }
  };

  const BondCard = ({ bond }) => {
    let pairedSubscriber = bond.pairedSubscriber;
    let status = bond.bond.status;

    let bondTicketStatuses = bond.bond.numRecords;
    const desiredStatusOrder = [
      "total",
      ...Object.keys(bondTicketStatuses).filter((key) => key !== "total"),
    ];
    let bondColor = generateAvatarColors(pairedSubscriber.name, bonds);

    return (
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          // overflow: "hidden",
          width: 250,
          minWidth: 250,
          cursor: "pointer",
          overflow: "clip",
          p: 1,
          borderLeft: `8px solid ${bondColor}`,
        }}
        elevation={0}
        onClick={() => handleSelectBond(bond)}
      >
        <Grid
          container
          dense="true"
          sx={{
            ml: -1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          spacing={1}
        >
          <Grid item xs={2.5}>
            <BondAvatar bond={bond} />
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{ marginRight: 1, fontWeight: 600, fontSize: "1.1rem" }}
            >
              {pairedSubscriber.name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "start",
            }}
          >
            <Typography
              variant="caption"
              sx={{ fontWeight: 600, color: getStatusColor(status) }}
            >
              {getBondStatusIcon(status)}
            </Typography>
          </Grid>
        </Grid>
        {/* Render ticket info */}
        <Stack sx={{ mt: 0.5 }}>
          {desiredStatusOrder.map((key) => (
            <Box
              key={key}
              sx={{ display: "flex", alignItems: "center", mb: 1 }}
            >
              <Box
                xs={key === "total" ? 4 : 6}
                sx={{
                  alignItems: "center",
                  display: "flex",
                  ml: key === "total" ? 0 : 4,
                }}
              >
                <Box sx={{ width: 100 }}>
                  {key === "total" ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ConfirmationNumber color="primary" />
                      <Typography
                        sx={{
                          ml: 1,
                        }}
                      >
                        Total
                      </Typography>
                    </Box>
                  ) : (
                    getStatusChip(key)
                  )}
                </Box>

                <Typography
                  sx={{
                    fontWeight: 800,
                    ml: 2,
                    color: key === "Error" ? red[700] : "black",
                  }}
                >
                  {bondTicketStatuses[key]}
                </Typography>
              </Box>
            </Box>
          ))}
        </Stack>
      </Paper>
    );
  };

  const AllBondCards = () => (
    <div id="bond-content">
      <Stack direction="row" spacing={2} sx={{ overflow: "auto" }}>
        {bonds.map((b, i) => (
          <BondCard bond={b} sx={{ width: "100%" }} key={i} />
        ))}
      </Stack>
    </div>
  );

  const bondPanelContent = () => (
    <div id="bond-content">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          paddingBottom: 1,
        }}
      >
        <NewBondDialog />

        <ToggleButtonGroup
          value={displayMode}
          defaultValue="cards"
          exclusive
          onChange={toggleDisplayMode}
          aria-label="bond view"
          size="small"
          color="primary"
        >
          <ToggleButton value="cards" aria-label="card view">
            <Dashboard sx={{ fontSize: "1rem" }} />
          </ToggleButton>
          <ToggleButton value="table" aria-label="table view">
            <ViewList sx={{ fontSize: "1rem" }} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {displayMode === "cards" && <AllBondCards />}
      {displayMode === "table" && <BondsTable bonds={bonds} />}
    </div>
  );

  let content;
  if (bondsStatus === "loading") {
    content = <Skeleton variant="rectangular" height={151} />;
  } else if (bondsStatus === "succeeded") {
    if (selectedBond) {
      content = <SelectedBondPanel />;
    } else {
      if (bonds.length === 0) {
        content = (
          <Box
            sx={{ display: "flex", paddingBottom: 1, flexDirection: "column" }}
          >
            <Typography
              variant="caption"
              sx={{ fontWeight: 700, marginBottom: 1 }}
            >
              You don't have any bonds yet. Click the button below to get
              started.
            </Typography>
            <NewBondDialog />
          </Box>
        );
      } else {
        content = bondPanelContent();
      }
    }
  } else if (bondsStatus === "failed") {
    //TODO: handle error content
    return <Typography color="warning">Error loading bonds.</Typography>;
  }

  return (
    <div id="bond-panel">
      <Box sx={{ display: "flex", paddingBottom: 1 }} alignItems="center">
        <Typography
          variant="h5"
          color="primary"
          sx={{
            fontWeight: 600,
            paddingRight: 1,
            cursor: "pointer",
          }}
          onClick={() => handleBondsTitleClick()}
        >
          Bonds
        </Typography>
        {selectedBond && (
          <Typography variant="h5" color="primary" sx={{ fontWeight: 600 }}>
            / {selectedBond.pairedSubscriber.name}
          </Typography>
        )}
      </Box>
      <Paper
        sx={{
          backgroundColor: primaryBgc,
          padding: 1.5,
          paddingBottom: 1.5,
          justifyContent: "center",
        }}
        elevation={0}
      >
        {content}
      </Paper>
    </div>
  );
}

export default BondsPanel;

import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

const DummyTest = ({ product, handleTestSuccess }) => {
  const testData = [
    {
      step: `Connecting to ${product}...`,
      status: "pending",
      result: `Connected to ${product}`,
    },
    {
      step: `Logging in...`,
      status: "pending",
      result: `Logged in`,
    },
    {
      step: "Opening ticket...",
      status: "pending",
      result: "Ticket opened",
    },
    {
      step: "Testing ticket...",
      status: "pending",
      result: "Test successful",
    },
  ];
  const [testSteps, setTestSteps] = useState(testData);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  useEffect(() => {
    const runTests = async () => {
      if (testSteps[currentStepIndex].status === "pending") {
        await new Promise((resolve) =>
          setTimeout(() => {
            setTestSteps((prevTestSteps) => {
              const newTestSteps = [...prevTestSteps];
              newTestSteps[currentStepIndex] = {
                ...testSteps[currentStepIndex],
                status: "success",
              };
              return newTestSteps;
            });
            resolve();
          }, Math.random() * 2000 + 1000)
        );
      }

      if (currentStepIndex < testSteps.length - 1) {
        setCurrentStepIndex(currentStepIndex + 1);
      } else {
        setTimeout(() => {
          handleTestSuccess();
        }, 1000);
      }
    };

    runTests();
  }, [currentStepIndex]);

  return (
    <List>
      {testSteps.map((step, index) => (
        <ListItem
          key={index}
          style={{
            display:
              index === currentStepIndex || step.status === "success"
                ? "flex"
                : "none",
          }}
        >
          <ListItemIcon>
            {step.status === "pending" ? (
              <CircularProgress size={25} />
            ) : (
              <Check sx={{ color: "success.main" }} />
            )}
          </ListItemIcon>
          <ListItemText
            primary={step.status === "success" ? step.result : step.step}
            primaryTypographyProps={{
              fontWeight: step.status === "success" ? 700 : 500,
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default DummyTest;

import React from "react";
import {
  Box,
  Autocomplete,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  Switch,
  capitalize,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import {
  parseRequiredField,
  parseBoolean,
  primaryBlue,
} from "../utils/jsutils";

const TextFieldWrapper = ({ field }) => {
  const { control } = useFormContext();
  return (
    <div className="form-field">
      <InputLabel
        shrink
        htmlFor={field.name}
        sx={{ fontWeight: 600, color: primaryBlue }}
      >
        {capitalize(field.caption)}
      </InputLabel>
      <Controller
        name={field.name}
        control={control}
        rules={{ required: parseRequiredField(field) }}
        render={({ field }) => (
          <TextField
            {...field}
            sx={{ marginBottom: field.helptext ? 0 : 1, paddingBottom: 1 }}
            multiline={field.multiline}
            rows={field.multiline ? 4 : 1}
            fullWidth
            size="small"
          />
        )}
      />
      {field.helptext && (
        <FormHelperText
          id="component-helptext-text"
          sx={{ marginTop: 0, marginBottom: 1 }}
        >
          {field.helptext}
        </FormHelperText>
      )}
    </div>
  );
};

const PasswordFieldWrapper = ({ field }) => {
  const { control } = useFormContext();
  return (
    <div className="form-field">
      <InputLabel
        shrink
        htmlFor={field.name}
        sx={{ fontWeight: 600, color: primaryBlue }}
      >
        {capitalize(field.caption)}
      </InputLabel>
      <Controller
        name={field.name}
        control={control}
        rules={{ required: parseRequiredField(field) }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            type="password"
            sx={{ marginBottom: field.helptext ? 0 : 1 }}
          />
        )}
      />
      {field.helptext && (
        <FormHelperText
          id="component-helptext-text"
          sx={{ marginTop: 0, marginBottom: 1 }}
        >
          {field.helptext}
        </FormHelperText>
      )}
    </div>
  );
};

const SelectFieldWrapper = ({ field }) => {
  const { control } = useFormContext();
  return (
    <div className="form-field">
      <InputLabel
        shrink
        htmlFor={field.name}
        sx={{ fontWeight: 600, color: primaryBlue }}
      >
        {capitalize(field.caption)}
      </InputLabel>
      <Controller
        name={field.name}
        control={control}
        rules={{ required: parseRequiredField(field) }}
        render={({ field }) => (
          <Select
            {...field}
            fullWidth
            sx={{ marginBottom: field.helptext ? 0 : 1 }}
          >
            {field.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {field.helptext && (
        <FormHelperText
          id="component-helptext-text"
          sx={{ marginTop: 0, marginBottom: 1 }}
        >
          {field.helptext}
        </FormHelperText>
      )}
    </div>
  );
};

const AutocompleteWrapper = ({ field }) => {
  const { control } = useFormContext();
  return (
    <div className="form-field">
      <InputLabel
        shrink
        htmlFor={field.name}
        sx={{ fontWeight: 600, color: primaryBlue }}
      >
        {capitalize(field.caption)}
      </InputLabel>
      <Controller
        name={field.name}
        control={control}
        rules={{ required: parseRequiredField(field) }}
        render={({ field }) => (
          <Autocomplete
            options={field.options}
            {...field}
            fullWidth
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      />
      {field.helptext && (
        <FormHelperText sx={{ marginTop: 0, marginBottom: 1 }}>
          {field.helptext}
        </FormHelperText>
      )}
    </div>
  );
};

const CheckboxWrapper = ({ field }) => {
  const { control } = useFormContext();

  return (
    <div className="form-field">
      <Controller
        name={field.name}
        control={control}
        value={parseBoolean(field)}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} />}
            label={capitalize(field.caption)}
            sx={{ color: primaryBlue }}
          />
        )}
      />
    </div>
  );
};

const SwitchWrapper = ({ field }) => {
  const { control } = useFormContext();
  return (
    <div className="form-field">
      <Controller
        name={field.name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Switch {...field} />}
            label={capitalize(field.caption)}
            sx={{ color: primaryBlue }}
          />
        )}
      />
    </div>
  );
};

function FormField({ field }) {
  if (!field || field === undefined) return null;
  switch (field.type) {
    case "password":
      return <PasswordFieldWrapper field={field} />;
    case "checkbox":
      return <CheckboxWrapper field={field} />;
    case "select":
      return <SelectFieldWrapper field={field} />;
    case "autocomplete":
      return <AutocompleteWrapper field={field} />;
    case "textarea":
    // return <CatalogTextAreaField {...props} helperText={helperText} />;
    case "script":
    //   return <CatalogScriptWrapper {...props} />;
    case "switch":
      return <SwitchWrapper field={field} />;
    case "text":
    default:
      return <TextFieldWrapper field={field} />;
  }
}

export default FormField;

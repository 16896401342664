import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ContainersApi } from "../../../apis/containerApi";

export const loadContainers = createAsyncThunk(
  "container/loadContainers",
  async (activeSubscriberId) => {
    const containers = await ContainersApi.getContainers(activeSubscriberId);
    return containers;
  }
);

const containerSlice = createSlice({
  name: "container",
  initialState: {
    data: {
      id: null,
      url: null,
      subscriberId: null,
      credentialName: null,
    },
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadContainers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadContainers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        state.data = action.payload;
      })
      .addCase(loadContainers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {} = containerSlice.actions;
export const selectContainerData = (state) => state.container.data;

export default containerSlice.reducer;

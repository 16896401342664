import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormContext } from "react-hook-form";
import ServiceDeskChip from "./ServiceDeskChip";

function ServicedeskAutocomplete({ watchProduct }) {
  const [sdValue, setSdValue] = useState(watchProduct ? watchProduct : "");

  const servicedesks = [
    { product: "BMC" },
    { product: "Cherwell" },
    { product: "Freshdesk" },
    { product: "Helpdesk" },
    { product: "Ivanti" },
    { product: "Jira" },
    { product: "ServiceNow" },
    { product: "Zendesk" },
  ];
  const popular = [
    { product: "Freshdesk" },
    { product: "Ivanti" },
    { product: "Jira" },
    { product: "ServiceNow" },
  ];

  const { setValue } = useFormContext();

  const handleAutocompleteChange = (event, value) => {
    setSdValue(value.product);
    setValue("product", value.product); // Update the form value for "product" field
  };

  const handleChipChange = (event, value) => {
    setSdValue(value.product);
    setValue("product", value.product);
  };

  return (
    <>
      <Autocomplete
        id="servicedesk-selector"
        options={servicedesks}
        // value={sdValue}
        fullWidth
        inputValue={sdValue}
        sx={{ marginTop: 2 }}
        // freeSolo
        autoHighlight
        name="product"
        getOptionLabel={(option) => (option.product ? option.product : option)}
        isOptionEqualToValue={(option, value) => option.product === value} //state is just sd.product, need to match option structure. state needs to be sd.product to play well with chips and matching label, bordercolor selection....
        onChange={handleAutocompleteChange} // Handle the onChange event
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading="lazy"
              width="30"
              src={`images/${option.product.toLowerCase()}.svg`}
              alt={`${option.product} logo`}
            />
            {option.product}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose a servicedesk"
            helperText="Select from popular choices or type the name of your product"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexGrow: 1,
          marginTop: 2,
        }}
      >
        {popular.map((sd) => {
          return (
            <ServiceDeskChip
              servicedesk={sd}
              handleClick={handleChipChange}
              watchProduct={watchProduct}
              key={sd.product}
            />
          );
        })}
      </Box>
    </>
  );
}

export default ServicedeskAutocomplete;

import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { InvitationApi } from "../../../apis/invitationApi";

function NewBondForm({ handleClose }) {
  const bonds = useSelector((state) => state.bonds.data);
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "Acme",
      email: "acme@servicedesk.com",
      subject: "Bond invitation on 1Bonding",
      message: "Hey Colin, here are the details of our bond!",
    },
  });

  const onSubmit = (data) => {
    InvitationApi.postInvitation(data).then(() => {
      enqueueSnackbar("Invitation sent!");
      handleClose();
    });
  };

  return (
    <Container maxWidth="md">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Name"
              fullWidth
              variant="outlined"
              margin="normal"
              error={errors.name ? true : false}
              helperText={errors.name ? "Name is required." : ""}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Email"
              fullWidth
              variant="outlined"
              margin="normal"
              error={errors.email ? true : false}
              helperText={errors.email ? "Email is required." : ""}
            />
          )}
        />
        <Controller
          name="subject"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Subject"
              fullWidth
              variant="outlined"
              margin="normal"
              error={errors.subject ? true : false}
              helperText={errors.subject ? "Subject is required." : ""}
            />
          )}
        />
        <Controller
          name="message"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Message"
              fullWidth
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
              error={errors.message ? true : false}
              helperText={errors.message ? "Message is required." : ""}
            />
          )}
        />
        <Button type="submit" variant="contained" sx={{ mt: 2 }}>
          Submit
        </Button>
      </form>
    </Container>
  );
}

export default NewBondForm;
